import React, { FC } from 'react';
import { useNavigate } from 'react-router';

import { url } from 'helpers';
import { useLang } from 'hooks';
import { useBusinessAccountFeeCommissionsQuery } from 'hooks/queries';
import { CardView } from 'components/layout';
import { Button } from 'components/ui';
import { BusinessAccount, BusinessAccountType } from 'types/models';

type FeeCommissionsViewProps = {
  businessAccount: BusinessAccount;
};

const FeeCommissionsView: FC<FeeCommissionsViewProps> = ({ businessAccount }) => {
  const lang = useLang();
  const navigate = useNavigate();

  const feeCommissionsQuery = useBusinessAccountFeeCommissionsQuery(businessAccount.id);

  return (
    <CardView
      title={lang.get('businessAccount.feeCommission.card.title')}
      caption={lang.choice('businessAccount.feeCommission.card.caption', feeCommissionsQuery.data?.total ?? 0)}
      actions={(
        <Button
          color="primary"
          variant="filled"
          onClick={() => {
            navigate(
              businessAccount.type === BusinessAccountType.INDIVIDUAL
                ? url.toIndividualFeeCommissions(businessAccount.id)
                : url.toBusinessAccountFeeCommissions(businessAccount.id),
            );
          }}
        >
          {lang.get('common.actions.view')}
        </Button>
      )}
    />
  );
};

export default FeeCommissionsView;
