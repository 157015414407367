import React, { FC } from 'react';

import { config, yup } from 'data';
import { corridorService } from 'services';
import { useForm, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { CorridorSelect } from 'components/selects';
import { Checkbox, Form, Modal, Select, Space } from 'components/ui';
import { Uuid } from 'types/common';
import { ModalBaseProps } from 'types/components';
import { CorridorTypeLevel, TransactionType } from 'types/models';
import { CorridorAddTypesParams } from 'types/services';

type FormValues = CorridorAddTypesParams & {
  corridorId: Uuid;
};

const levels = Object.values(CorridorTypeLevel);
const transactionTypes = Object.values(TransactionType);

const initialValues: Omit<FormValues, 'level'> = {
  corridorId: '',
  types: [],
};

const validationSchema = yup.object({
  corridorId: yup.string().required().uuid(),
  level: yup.string().required().oneOf(levels),
  types: yup.array().required().min(1).of(
    yup.string().required().oneOf(transactionTypes),
  ),
});

const AddCorridorTypeModal: FC<ModalBaseProps> = ({ open, onClose }) => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const addCorridorTypeMutation = useMutation({
    mutationFn: (values: FormValues) => corridorService.addCorridorTypes(values.corridorId, values),
    onSuccess: () => queryInvalidate([config.CORRIDOR_TYPES_QUERY_KEY]),
    successNotification: lang.get('corridorType.modal.addSuccess'),
  });

  const handleSubmit = async (values: FormValues) => {
    await addCorridorTypeMutation.mutateAsync(values);

    onClose();
  };

  return (
    <Modal
      title={lang.get('corridorType.modal.addTitle')}
      caption={lang.get('corridorType.modal.addCaption')}
      okText={lang.get('common.actions.add')}
      width="small"
      open={open}
      confirmLoading={addCorridorTypeMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >
        <Form.Item name="corridorId" label={lang.get('common.form.corridor.label')}>
          <CorridorSelect />
        </Form.Item>
        <Form.Item name="level" label={lang.get('corridorType.modal.level.label')}>
          <Select
            placeholder={lang.get('corridorType.modal.level.placeholder')}
            options={levels.map((level) => ({
              value: level,
              label: lang.get(`corridorType.levels.${level.toLowerCase()}`),
            }))}
          />
        </Form.Item>
        <Form.Item name="types" label={lang.get('corridorType.modal.transactionTypes.label')}>
          <Checkbox.Group>
            <Space direction="vertical">
              {transactionTypes.map((type) => (
                <Checkbox key={type} value={type}>
                  {lang.get(`transaction.types.${type.toLowerCase()}`)}
                </Checkbox>
              ))}
            </Space>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCorridorTypeModal;
