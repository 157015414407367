import React from 'react';

import { useLang } from 'hooks';
import { DashboardLayout } from 'layouts';
import { Grid } from 'components/ui';

import List from './List';
import Roles from './Roles';

const UsersPage = () => {
  const lang = useLang();

  return (
    <DashboardLayout title={lang.get('user.list.title')}>
      <Grid.Row gutter={[8, 8]}>
        <Grid.Col span={24} md={12}>
          <Roles />
        </Grid.Col>
        <Grid.Col span={24}>
          <List />
        </Grid.Col>
      </Grid.Row>
    </DashboardLayout>
  );
};

export default UsersPage;
