import { config } from 'data';
import { corridorService } from 'services';
import { useQuery } from 'hooks';
import { Uuid } from 'types/common';

const useCorridorQuery = (id?: Uuid) => useQuery({
  queryKey: [config.CORRIDOR_QUERY_KEY, id],
  queryFn: () => id ? corridorService.getCorridor(id) : null,
});

export default useCorridorQuery;
