import React, { FC } from 'react';

import { formatter } from 'helpers';
import { useLang } from 'hooks';
import { UserInfo } from 'components/layout';
import { Descriptions, DiffViewer, Modal } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { AuditLog } from 'types/models';

import styles from './styles.module.css';

type AuditLogModalProps = ModalBaseProps & {
  log: AuditLog;
};

const AuditLogModal: FC<AuditLogModalProps> = ({
  log,
  open,
  onClose,
}) => {
  const lang = useLang();

  return (
    <Modal
      title={lang.get('auditLog.modal.title')}
      caption={lang.get('auditLog.modal.caption')}
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      width="large"
      open={open}
      onCancel={onClose}
    >

      <Descriptions
        items={[{
          label: lang.get('auditLog.modal.date.label'),
          children: formatter.formatDateTime(log.createdAt),
        }, {
          label: lang.get('auditLog.modal.user.label'),
          children: (
            <UserInfo
              name={formatter.formatName(log.creator.firstName, log.creator.lastName)}
              email={log.creator.email}
            />
          ),
        }, {
          label: lang.get('auditLog.modal.action.label'),
          children: lang.get(`auditLog.actions.${log.action.toLowerCase()}`),
        }, {
          label: lang.get('auditLog.modal.object.label'),
          children: lang.get(`auditLog.objects.${log.objectType.toLowerCase()}`),
        }]}
      />

      {Boolean(log.oldValue || log.newValue) && (
        <div className={styles.modal__diff}>
          <DiffViewer
            leftTitle={lang.get('auditLog.modal.oldValue.label')}
            rightTitle={lang.get('auditLog.modal.newValue.label')}
            oldValue={log.oldValue}
            newValue={log.newValue}
          />
        </div>
      )}

    </Modal>
  );
};

export default AuditLogModal;
