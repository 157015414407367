import React from 'react';
import { useNavigate } from 'react-router';

import { url } from 'helpers';
import { useLang } from 'hooks';
import { useUserRolesQuery } from 'hooks/queries';
import { Can, CardView } from 'components/layout';
import { Button } from 'components/ui';
import { UserPermission } from 'types/models';

const RolesView = () => {
  const lang = useLang();
  const navigate = useNavigate();

  const rolesQuery = useUserRolesQuery();

  return (
    <Can permissions={[UserPermission.USER_ROLES_VIEW, UserPermission.USER_ROLES_UPDATE]}>
      <CardView
        title={lang.get('role.list.title')}
        caption={lang.choice('role.list.caption', rolesQuery.data?.total ?? 0)}
        actions={(
          <Button
            color="primary"
            variant="filled"
            onClick={() => navigate(url.toUserRoles())}
          >
            {lang.get('common.actions.view')}
          </Button>
        )}
      />
    </Can>
  );
};

export default RolesView;
