import { useCallback, useMemo, useState } from 'react';
import { useSessionStorage } from '@uidotdev/usehooks';
import isEqual from 'lodash/isEqual';

import { config } from 'data';
import { TableChangeHandler, TableData, TablePagination } from 'types/components';
import { TableKey, TableParams } from 'types/hooks';

const STORAGE_KEY_PREFIX = 'table:';

type TableState<DataType, ParamsType extends TableParams> = {
  pagination: TablePagination<DataType>;
  params: ParamsType;
};

const prepareStorageKey = (key: TableKey) => STORAGE_KEY_PREFIX + key.filter(Boolean).join(':');

const useTable = <
  DataType,
  ParamsType extends TableParams = TableParams
>(
  key: TableKey,
  initialParams: ParamsType = {} as ParamsType,
) => {
  const [exporting, setExporting] = useState(false);
  const [data, setData] = useState<TableData<DataType>>([]);
  const [selectedData, setSelectedData] = useState<TableData<DataType>>([]);

  const [state, saveState] = useSessionStorage<TableState<DataType, ParamsType>>(prepareStorageKey(key), {
    pagination: false,
    params: initialParams,
  });

  const setPagination = useCallback((pagination: TablePagination<DataType>) => {
    if (pagination) {
      pagination.current = pagination.current || 1;
      pagination.pageSize = pagination.pageSize || config.DEFAULT_PAGINATION_LIMIT;
      pagination.total = pagination.total || 0;

      let lastPage = pagination.total / pagination.pageSize;

      lastPage = Math.ceil(lastPage);
      lastPage = Math.max(lastPage, 1);

      if (pagination.current > lastPage) {
        pagination.current = lastPage;
      }
    }

    saveState((prevState) => ({ ...prevState, pagination }));
  }, [saveState]);

  const setParams = useCallback(<Key extends string & keyof ParamsType>(params: Partial<Record<Key, ParamsType[Key]>>) => {
    saveState((prevState) => ({
      ...prevState,
      pagination: !isEqual(prevState.params, params)
        ? { ...prevState.pagination, current: 1 }
        : prevState.pagination,
      params: params as ParamsType,
    }));
  }, [saveState]);

  const reset = useCallback(() => {
    setSelectedData([]);
    setPagination({});
  }, [setPagination]);

  const handleChange = useCallback<TableChangeHandler<DataType>>((pagination) => {
    saveState((prevState) => ({ ...prevState, pagination }));
  }, [saveState]);

  return useMemo(() => ({
    exporting,
    data,
    selectedData,
    pagination: state.pagination ?? false,
    page: state.pagination ? state.pagination.current : 1,
    params: state.params ?? initialParams,
    setExporting,
    setData,
    setSelectedData,
    setPagination,
    setParams,
    reset,
    onChange: handleChange,
  }), [
    initialParams,
    exporting,
    data,
    selectedData,
    state,
    setPagination,
    setParams,
    reset,
    handleChange,
  ]);
};

export default useTable;
