import { config } from 'data';
import { corridorService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useQuery } from 'hooks';
import { CorridorsParams } from 'types/services';

const useAllCorridorsQuery = (params?: CorridorsParams) => useQuery({
  queryKey: [config.CORRIDORS_QUERY_KEY, 'all', params],
  queryFn: () => fetchPaginatedResponseFully(corridorService.getCorridors, params),
});

export default useAllCorridorsQuery;
