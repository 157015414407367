import { config } from 'data';
import { businessAccountService } from 'services';
import { useQuery } from 'hooks';
import { Uuid } from 'types/common';
import { FeeCommissionsParams } from 'types/services';

const useBusinessAccountFeeCommissionsQuery = (id: Uuid, params?: FeeCommissionsParams) => useQuery({
  queryKey: [config.BUSINESS_ACCOUNT_FEE_COMMISSIONS_QUERY_KEY, id, params],
  queryFn: () => businessAccountService.getBusinessAccountFeeCommissions(id, params),
});

export default useBusinessAccountFeeCommissionsQuery;
