import { config } from 'data';
import { corridorTypeService } from 'services';
import { useQuery } from 'hooks';
import { CorridorTypesParams } from 'types/services';

const useCorridorTypesQuery = (params?: CorridorTypesParams) => useQuery({
  queryKey: [config.CORRIDOR_TYPES_QUERY_KEY, params],
  queryFn: () => corridorTypeService.getTypes(params),
});

export default useCorridorTypesQuery;
