import { axios } from 'data';
import { Uuid } from 'types/common';

import {
  BusinessApplication,
  BusinessApplicationAddress,
  BusinessApplicationDetails,
  BusinessApplicationIndustry,
} from 'types/models';

import {
  BusinessApplicationDetailsUpdateParams,
  BusinessApplicationParams,
  BusinessApplicationRejectParams,
  BusinessApplicationsParams,
  BusinessApplicationsResponse,
  BusinessApplicationVerifyParams,
} from 'types/services';

import { preparePaginatableParams, preparePaginatedData } from './helpers';

const getBusinessApplications = async (params: BusinessApplicationsParams = {}) => {
  const response = await axios.get<BusinessApplicationsResponse>('/core/admin/business-application', {
    params: preparePaginatableParams(params),
  });

  return preparePaginatedData<BusinessApplication>(response.data);
};

const getBusinessApplication = async (id: Uuid) => {
  const response = await axios.get<BusinessApplication>(`/core/admin/business-application/${id}`);

  return response.data;
};

const createBusinessApplication = async (params: BusinessApplicationParams) => {
  await axios.post<never>('/core/admin/business-application', params);
};

const updateBusinessApplication = async (id: Uuid, params: Partial<BusinessApplicationParams>) => {
  await axios.patch<never>(`/core/admin/business-application/${id}/update`, params);
};

const approveBusinessApplication = async (id: Uuid) => {
  await axios.put<never>(`/core/admin/business-application/${id}/approved`);
};

const rejectBusinessApplication = async (id: Uuid, params: BusinessApplicationRejectParams) => {
  await axios.put<never>(`/core/admin/business-application/${id}/declined`, params);
};

const verifyBusinessApplication = async (kybId: Uuid, params: BusinessApplicationVerifyParams) => {
  await axios.put<never>(`/core/admin/kyb/${kybId}/verified`, params);
};

const unverifyBusinessApplication = async (kybId: Uuid, params: BusinessApplicationRejectParams) => {
  await axios.put<never>(`/core/admin/kyb/${kybId}/unverified`, params);
};

const getBusinessApplicationDetails = async (id: Uuid) => {
  const response = await axios.get<BusinessApplicationDetails>(`/core/admin/business-application/${id}/details`);

  return response.data;
};

const updateBusinessApplicationDetails = async (id: Uuid, params: BusinessApplicationDetailsUpdateParams) => {
  await axios.put<never>(`/core/admin/business-application/${id}/details`, params);
};

const getBusinessApplicationIndustries = async () => {
  const response = await axios.get<BusinessApplicationIndustry[]>('/core/dictionary/industries');

  return response.data;
};

const getBusinessApplicationKybDetails = async (kybId: Uuid) => {
  const response = await axios.get<BusinessApplicationAddress>(`/core/admin/kyb/${kybId}/details`);

  return response.data;
};

const businessApplicationService = {
  getBusinessApplications,
  getBusinessApplication,
  createBusinessApplication,
  updateBusinessApplication,
  approveBusinessApplication,
  rejectBusinessApplication,
  verifyBusinessApplication,
  unverifyBusinessApplication,
  getBusinessApplicationDetails,
  updateBusinessApplicationDetails,
  getBusinessApplicationIndustries,
  getBusinessApplicationKybDetails,
};

export default businessApplicationService;
