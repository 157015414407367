import React, { FC } from 'react';

import { config, moment, yup } from 'data';
import { clientService } from 'services';
import { useForm, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { CountrySelect } from 'components/selects';
import { DatePicker, Form, Input, Modal } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { ClientCreateParams } from 'types/services';

type FormValues = ClientCreateParams;

const initialValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  birthdate: '',
  residenceCountryCode: '',
};

const validationSchema = yup.object({
  firstName: yup.string().required().trim().max(config.NAME_MAX_LENGTH),
  lastName: yup.string().required().trim().max(config.NAME_MAX_LENGTH),
  email: yup.string().required().email(),
  phone: yup.string().notRequired().phone(),
  birthdate: yup.string().required(),
  residenceCountryCode: yup.string().required().country(),
});

const CreateClientModal: FC<ModalBaseProps> = ({ open, onClose }) => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const createClientMutation = useMutation({
    mutationFn: clientService.createClient,
    onSuccess: () => queryInvalidate([config.CLIENTS_QUERY_KEY]),
    successNotification: lang.get('client.modal.createSuccess'),
  });

  const handleSubmit = async (values: FormValues) => {
    await createClientMutation.mutateAsync(values);

    onClose();
  };

  return (
    <Modal
      title={lang.get('client.modal.createTitle')}
      caption={lang.get('client.modal.createCaption')}
      okText={lang.get('common.actions.create')}
      open={open}
      confirmLoading={createClientMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >
        <Form.Columns>
          <Form.Item name="firstName" label={lang.get('common.form.firstName.label')}>
            <Input placeholder={lang.get('common.form.firstName.placeholder')} />
          </Form.Item>
          <Form.Item name="lastName" label={lang.get('common.form.lastName.label')}>
            <Input placeholder={lang.get('common.form.lastName.placeholder')} />
          </Form.Item>
        </Form.Columns>
        <Form.Columns>
          <Form.Item name="birthdate" label={lang.get('common.form.birthday.label')}>
            <DatePicker maxDate={moment().subtract(1, 'day').endOf('day')} allowClear={false} />
          </Form.Item>
          <Form.Item name="residenceCountryCode" label={lang.get('common.form.country.label')}>
            <CountrySelect />
          </Form.Item>
        </Form.Columns>
        <Form.Columns>
          <Form.Item name="email" label={lang.get('common.form.email.label')}>
            <Input.Email />
          </Form.Item>
          <Form.Item name="phone" label={lang.get('common.form.phone.label')}>
            <Input.Phone />
          </Form.Item>
        </Form.Columns>
      </Form>
    </Modal>
  );
};

export default CreateClientModal;
