import { axios } from 'data';
import { Uuid } from 'types/common';
import { Corridor } from 'types/models';

import { CorridorAddTypesParams, CorridorParams, CorridorsParams, CorridorsResponse } from 'types/services';

import { preparePaginatableParams, preparePaginatedData } from './helpers';

const getCorridors = async (params: CorridorsParams = {}) => {
  const response = await axios.get<CorridorsResponse>('/payments/admin/currency-corridor', {
    params: preparePaginatableParams(params),
  });

  return preparePaginatedData<Corridor>(response.data);
};

const getCorridor = async (id: Uuid) => {
  const response = await axios.get<Corridor>(`/payments/admin/currency-corridor/${id}`);

  return response.data;
};

const createCorridor = async (params: CorridorParams) => {
  await axios.post<never>('/payments/admin/currency-corridor', params);
};

const deleteCorridor = async (id: Uuid) => {
  await axios.delete<never>(`/payments/admin/currency-corridor/${id}`);
};

const addCorridorTypes = async (id: Uuid, params: CorridorAddTypesParams) => {
  await axios.put<never>(`/payments/admin/currency-corridor/${id}/type`, params);
};

const corridorService = {
  getCorridors,
  getCorridor,
  createCorridor,
  deleteCorridor,
  addCorridorTypes,
};

export default corridorService;
