import React, { FC } from 'react';

import { config } from 'data';
import { useLang } from 'hooks';
import { useAllCurrenciesQuery } from 'hooks/queries';
import { Select } from 'components/ui';
import { SelectProps } from 'types/components';
import { CurrencyCode } from 'types/models';

type CurrencySelectProps = Omit<SelectProps, 'options' | 'loading'> & {
  activeCodes?: CurrencyCode[];
  includeAny?: boolean;
  onlyActive?: boolean;
};

const CurrencySelect: FC<CurrencySelectProps> = ({
  activeCodes,
  includeAny,
  onlyActive,
  showSearch = true,
  ...props
}) => {
  const lang = useLang();

  const currenciesQuery = useAllCurrenciesQuery({ active: onlyActive || undefined });

  const currencies = currenciesQuery.data
    ?.filter((currency) => !activeCodes || activeCodes.includes(currency.code))
    ?.filter((currency) => !includeAny || currency.code !== config.CURRENCY_CODE_ANY);

  return (
    <Select
      placeholder={lang.get('common.form.currency.placeholder')}
      options={[
        ...(includeAny ? [{
          value: config.CURRENCY_CODE_ANY,
          label: lang.get('common.actions.any'),
        }] : []),
        ...(currencies?.map((currency) => ({
          value: currency.code,
          label: currency.code,
        })) ?? []),
      ]}
      loading={currenciesQuery.isFetching}
      showSearch={showSearch}
      {...props}
    />
  );
};

export default CurrencySelect;
