import React, { FC } from 'react';

import { config } from 'data';
import { useLang } from 'hooks';
import { useAllCountriesQuery } from 'hooks/queries';
import { Select } from 'components/ui';
import { SelectProps } from 'types/components';

type CountrySelectProps = Omit<SelectProps, 'options' | 'loading'> & {
  includeAny?: boolean;
};

const CountrySelect: FC<CountrySelectProps> = ({
  includeAny,
  showSearch = true,
  ...props
}) => {
  const lang = useLang();

  const countriesQuery = useAllCountriesQuery();

  const countries = countriesQuery.data?.filter((country) => !includeAny || country.code !== config.COUNTRY_CODE_ANY);

  return (
    <Select
      placeholder={lang.get('common.form.country.placeholder')}
      options={[
        ...(includeAny ? [{
          value: config.COUNTRY_CODE_ANY,
          label: lang.get('common.actions.any'),
        }] : []),
        ...(countries?.map((country) => ({
          value: country.code,
          label: country.name,
        })) ?? []),
      ]}
      loading={countriesQuery.isFetching}
      showSearch={showSearch}
      {...props}
    />
  );
};

export default CountrySelect;
