import React, { FC } from 'react';

import { useLang } from 'hooks';
import { DashboardLayout } from 'layouts';
import { Grid } from 'components/ui';
import { BusinessAccountType } from 'types/models';

import Accounts from './Accounts';
import Balances from './Balances';

type BusinessAccountsPageProps = {
  type: BusinessAccountType;
};

const BusinessAccountsPage: FC<BusinessAccountsPageProps> = ({ type }) => {
  const lang = useLang();

  return (
    <DashboardLayout title={
      type === BusinessAccountType.INDIVIDUAL
        ? lang.get('businessAccount.list.individualsTitle')
        : lang.get('businessAccount.list.title')
    }>
      <Grid.Row gutter={[8, 8]}>
        <Grid.Col span={24} md={12}>
          <Balances type={type} />
        </Grid.Col>
        <Grid.Col span={24}>
          <Accounts type={type} />
        </Grid.Col>
      </Grid.Row>
    </DashboardLayout>
  );
};

export default BusinessAccountsPage;
