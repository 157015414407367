import { axios } from 'data';
import { Uuid } from 'types/common';
import { VirtualAccountStatus } from 'types/models';

const approveVirtualAccount = async (id: Uuid) => {
  await axios.put<never>(`/payments/admin/virtual-accounts/${id}/${VirtualAccountStatus.ACCEPTED}`);
};

const rejectVirtualAccount = async (id: Uuid) => {
  await axios.put<never>(`/payments/admin/virtual-accounts/${id}/${VirtualAccountStatus.REJECTED}`);
};

const virtualAccountService = {
  approveVirtualAccount,
  rejectVirtualAccount,
};

export default virtualAccountService;
