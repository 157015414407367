import { config } from 'data';
import { corridorService } from 'services';
import { useQuery } from 'hooks';
import { CorridorsParams } from 'types/services';

const useCorridorsQuery = (params?: CorridorsParams) => useQuery({
  queryKey: [config.CORRIDORS_QUERY_KEY, params],
  queryFn: () => corridorService.getCorridors(params),
});

export default useCorridorsQuery;
