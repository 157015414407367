import { config } from 'data';
import { corridorTypeService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useQuery } from 'hooks';
import { CorridorTypesParams } from 'types/services';

const useAllCorridorTypesQuery = (params?: CorridorTypesParams) => useQuery({
  queryKey: [config.CORRIDOR_TYPES_QUERY_KEY, 'all', params],
  queryFn: () => fetchPaginatedResponseFully(corridorTypeService.getTypes, params),
});

export default useAllCorridorTypesQuery;
