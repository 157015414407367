import { config } from 'data';
import { corridorTypeService } from 'services';
import { useQuery } from 'hooks';
import { Uuid } from 'types/common';

const useCorridorTypeQuery = (id?: Uuid) => useQuery({
  queryKey: [config.CORRIDOR_TYPE_QUERY_KEY, id],
  queryFn: () => id ? corridorTypeService.getType(id) : null,
});

export default useCorridorTypeQuery;
