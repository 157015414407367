import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router';

import { yup } from 'data';
import { url } from 'helpers';
import { authService } from 'services';
import { useForm, useLang, useMutation } from 'hooks';
import { PageLayout } from 'layouts';
import { Button, Flex, Form, Input } from 'components/ui';
import { Uuid } from 'types/common';
import { AuthChallenge } from 'types/services';

type FormValues = {
  password: string;
  passwordConfirmation: string;
};

const initialValues: FormValues = {
  password: '',
  passwordConfirmation: '',
};

const validationSchema = yup.object({
  password: yup.string().required().password(),
  passwordConfirmation: yup.string().required().confirmation(yup.ref('password')),
});

const PasswordChangePage = () => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const changePasswordMutation = useMutation({
    mutationFn: (values: FormValues) => authService.changePassword({
      ...location.state as AuthChallenge,
      hash: params.hash as Uuid,
      newPassword: values.password,
    }),
    onSuccess: () => navigate(url.toDashboard()),
    successNotification: lang.get('passwordChange.success'),
  });

  useEffect(() => {
    if (!location.state) {
      navigate(url.toLogin());
    }
  }, [location.state, navigate]);

  return (
    <PageLayout
      title={lang.get('passwordChange.title')}
      heading={lang.get('passwordChange.heading')}
      caption={lang.get('passwordChange.caption', { email: location.state?.email })}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={changePasswordMutation.mutateAsync}
      >
        <Form.Item name="password" label={lang.get('passwordChange.password.label')}>
          <Input type="password" placeholder={lang.get('passwordChange.password.placeholder')} />
        </Form.Item>
        <Form.Item name="passwordConfirmation" label={lang.get('passwordChange.passwordConfirmation.label')}>
          <Input type="password" placeholder={lang.get('passwordChange.passwordConfirmation.placeholder')} />
        </Form.Item>
        <Form.Item>
          <Flex gap="small" vertical>
            <Button htmlType="submit" block loading={changePasswordMutation.isPending}>
              {lang.get('common.actions.continue')}
            </Button>
            <Link to={url.toLogin()}>
              <Button type="link" block>
                {lang.get('passwordChange.back')}
              </Button>
            </Link>
          </Flex>
        </Form.Item>
      </Form>
    </PageLayout>
  );
};

export default PasswordChangePage;
