import React, { FC } from 'react';

import { config } from 'data';
import { formatter } from 'helpers';
import { clientService } from 'services';
import { useAuth, useCountryFormatter, useLang, useModal, useMutation, useQueryInvalidate } from 'hooks';
import { VerificationDescriptions } from 'components/layout';
import { Button, Descriptions, Form, Link, Modal, PopconfirmButton } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { Client, ClientStatus as EClientStatus, UserPermission } from 'types/models';
import { ClientVerifyParams } from 'types/services';

import Status from './Status';
import VerifyModal from './VerifyModal';

type ViewClientModalProps = ModalBaseProps & {
  client: Client;
};

const ViewClientModal: FC<ViewClientModalProps> = ({
  client,
  open,
  onClose,
}) => {
  const auth = useAuth();
  const countryFormatter = useCountryFormatter();
  const lang = useLang();
  const verifyModal = useModal();
  const queryInvalidate = useQueryInvalidate();

  const invalidateClientQueries = async () => {
    await queryInvalidate([config.CLIENTS_QUERY_KEY]);
    await queryInvalidate([config.CLIENT_QUERY_KEY, client.id]);
  };

  const verifyClientMutation = useMutation({
    mutationFn: (values: ClientVerifyParams) => clientService.verifyClient(client.id, values),
    onSuccess: invalidateClientQueries,
    successNotification: lang.get('client.modal.verifySuccess', { email: client.email }),
  });

  const unverifyClientMutation = useMutation({
    mutationFn: () => clientService.unverifyClient(client.id),
    onSuccess: invalidateClientQueries,
    successNotification: lang.get('client.modal.unverifySuccess', { email: client.email }),
  });

  const activateClientMutation = useMutation({
    mutationFn: () => clientService.activateClient(client.id),
    onSuccess: invalidateClientQueries,
    successNotification: lang.get('client.modal.activateSuccess', { email: client.email }),
  });

  const deactivateClientMutation = useMutation({
    mutationFn: () => clientService.deactivateClient(client.id),
    onSuccess: invalidateClientQueries,
    successNotification: lang.get('client.modal.deactivateSuccess', { email: client.email }),
  });

  const resetClientPasswordMutation = useMutation({
    mutationFn: () => clientService.resetClientPassword(client.id),
    successNotification: lang.get('client.modal.resetPassword.success', { email: client.email }),
  });

  const handleVerify = async (values: ClientVerifyParams) => {
    await verifyClientMutation.mutateAsync(values);

    onClose();
  };

  const handleUnverify = async () => {
    await unverifyClientMutation.mutateAsync();

    onClose();
  };

  const handleActivate = async () => {
    await activateClientMutation.mutateAsync();

    onClose();
  };

  const handleDeactivate = async () => {
    await deactivateClientMutation.mutateAsync();

    onClose();
  };

  const canEdit = auth.can(UserPermission.INDIVIDUALS_UPDATE);

  return (
    <Modal
      title={lang.get('client.modal.viewTitle', { email: client.email })}
      caption={lang.get('client.modal.viewCaption')}
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      open={open}
      onCancel={onClose}
    >

      <Form.ActionsItem hidden={!canEdit}>

        <PopconfirmButton
          title={lang.get('client.modal.resetPassword.title')}
          description={lang.get('client.modal.resetPassword.caption')}
          loading={resetClientPasswordMutation.isPending}
          onConfirm={() => resetClientPasswordMutation.mutateAsync()}
        >
          {lang.get('client.modal.resetPassword.button')}
        </PopconfirmButton>

        {client.verification?.verified ? (
          <PopconfirmButton
            title={lang.get('client.modal.unverifyTitle')}
            danger
            loading={unverifyClientMutation.isPending}
            onConfirm={handleUnverify}
          >
            {lang.get('common.actions.unverify')}
          </PopconfirmButton>
        ) : (
          <Button type="primary" ghost onClick={verifyModal.show}>
            {lang.get('common.actions.verify')}
          </Button>
        )}

        {client.status === EClientStatus.ACTIVE ? (
          <PopconfirmButton
            title={lang.get('client.modal.deactivateTitle')}
            danger
            loading={deactivateClientMutation.isPending}
            onConfirm={handleDeactivate}
          >
            {lang.get('common.actions.deactivate')}
          </PopconfirmButton>
        ) : (
          <PopconfirmButton
            title={lang.get('client.modal.activateTitle')}
            type="primary"
            ghost
            loading={activateClientMutation.isPending}
            onConfirm={handleActivate}
          >
            {lang.get('common.actions.activate')}
          </PopconfirmButton>
        )}

      </Form.ActionsItem>

      <Form.Divider hidden={!canEdit} />

      <Descriptions
        items={[{
          label: lang.get('common.form.firstName.label'),
          children: client.firstName ?? '-',
        }, {
          label: lang.get('common.form.lastName.label'),
          children: client.lastName ?? '-',
        }, {
          label: lang.get('common.form.birthday.label'),
          children: formatter.formatDate(client.birthdate),
        }, {
          label: lang.get('common.form.country.label'),
          children: countryFormatter.format(client.residenceCountryCode, '-'),
        }, {
          label: lang.get('common.form.email.label'),
          children: <Link href={`mailto:${client.email}`}>{client.email}</Link>,
        }, {
          label: lang.get('common.form.phone.label'),
          children: client.phone
            ? <Link href={`tel:${client.phone}`}>{client.phone}</Link>
            : '-',
        }, {
          label: lang.get('common.form.status.label'),
          children: <Status status={client.status} />,
        }]}
      />

      <Form.Divider />

      <VerificationDescriptions verification={client.verification} />

      <VerifyModal
        client={client}
        open={verifyModal.open}
        loading={verifyClientMutation.isPending}
        onClose={verifyModal.hide}
        onSubmit={handleVerify}
      />

    </Modal>
  );
};

export default ViewClientModal;
