import { config, moment } from 'data';
import { Nullish } from 'types/common';
import { CurrencyCode } from 'types/models';

const formatCurrency = (amount: number, currency: CurrencyCode) => {
  const formatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency,
    currencyDisplay: 'code',
  });

  return formatter.format(amount)
    .replace(/(-)?([a-z]+)\s(.+)/i, '$1$3 $2') // -USD 1,000.00 -> -1,000.00 USD
    .replace(/,/g, ' '); // -1,000.00 USD -> -1 000.00 USD
};

const formatDate = (date: string) => {
  return moment(date).format(config.DATE_FORMAT);
};

const formatDateTime = (date: string) => {
  return moment(date).format(config.DATE_TIME_FORMAT);
};

const formatName = (firstName: Nullish<string>, lastName: Nullish<string>) => {
  return [firstName, lastName].filter(Boolean).join(' ');
};

const formatNumber = (value: number, precision = 2) => {
  const formatter = new Intl.NumberFormat('en', {
    style: 'decimal',
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });

  return formatter.format(value)
    .replace(/,/g, ' '); // -1,000.00 -> -1 000.00
};

const formatPercent = (value: number, precision = 2) => {
  const formatter = new Intl.NumberFormat('en', {
    style: 'percent',
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });

  return formatter.format(value / 100)
    .replace(/,/g, ' '); // -1,000.00% -> -1 000.00%;
};

const formatTime = (date: string) => {
  return moment(date).format(config.TIME_FORMAT);
};

const formatter = {
  formatCurrency,
  formatDate,
  formatDateTime,
  formatName,
  formatNumber,
  formatPercent,
  formatTime,
};

export default formatter;
