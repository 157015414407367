import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { url } from 'helpers';
import { useAuth } from 'hooks';
import { RouteProps } from 'types/routes';

const PublicRoute: FC<RouteProps> = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const returnUrl = location.state?.returnUrl;

  useEffect(() => {
    if (auth.authenticated) {
      navigate(returnUrl ?? url.toDashboard());
    }
  }, [auth.authenticated, navigate, returnUrl]);

  return !auth.authenticated && children;
};

export default PublicRoute;
