import React, { FC, useEffect } from 'react';

import { config, yup } from 'data';
import { businessAccountService } from 'services';
import { useAuth, useForm, useFormWatch, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { useBusinessAccountDetailsQuery } from 'hooks/queries';
import { CurrencySelect } from 'components/selects';
import { Form, Input, Modal, Space, Switch } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { BusinessAccount, CurrencyCode } from 'types/models';
import { BusinessAccountUpdateDetailsParams } from 'types/services';

import styles from './styles.module.css';

type FormValues = Pick<BusinessAccountUpdateDetailsParams, 'lowBalanceLevel'> & {
  lowBalanceEnabled: boolean;
  lowBalanceCurrency: CurrencyCode;
};

const initialValues: FormValues = {
  lowBalanceEnabled: false,
  lowBalanceCurrency: config.DEFAULT_CURRENCY,
};

const validationSchema = yup.object({
  lowBalanceEnabled: yup.boolean().required(),
  lowBalanceLevel: yup
    .number()
    .notRequired()
    .decimal()
    .min(config.BUSINESS_ACCOUNT_LOW_BALANCE_MIN)
    .max(config.BUSINESS_ACCOUNT_LOW_BALANCE_MAX)
    .default(0)
    .transform((value) => value || 0)
    .when('lowBalanceEnabled', ([lowBalanceEnabled], schema) => lowBalanceEnabled ? schema.required() : schema),
  lowBalanceCurrency: yup.string().required().currency(),
});

type NotificationsModalProps = ModalBaseProps & {
  businessAccount: BusinessAccount;
};

const NotificationsModal: FC<NotificationsModalProps> = ({
  businessAccount,
  open,
  onClose,
}) => {
  const auth = useAuth();
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const currentLowBalanceEnabled = useFormWatch('lowBalanceEnabled', form);

  const businessAccountDetailsQuery = useBusinessAccountDetailsQuery(businessAccount.id);

  const updateBusinessAccountDetailsMutation = useMutation({
    mutationFn: (values: FormValues) => businessAccountService.updateBusinessAccountDetails(businessAccount.id, values),
    onSuccess: () => queryInvalidate([config.BUSINESS_ACCOUNT_DETAILS_QUERY_KEY, businessAccount.id]),
    successNotification: lang.get('businessAccount.notifications.modal.success', { name: businessAccount.name }),
  });

  const handleSubmit = async (values: FormValues) => {
    await updateBusinessAccountDetailsMutation.mutateAsync(values);

    onClose();
  };

  useEffect(() => {
    if (open && businessAccountDetailsQuery.data) {
      form.setFieldsValue({
        ...businessAccountDetailsQuery.data,
        lowBalanceEnabled: Boolean(businessAccountDetailsQuery.data.lowBalanceLevel),
      });
    }
  }, [open, form, businessAccountDetailsQuery.data]);

  useEffect(() => {
    if (form.isFieldTouched('lowBalanceEnabled')) {
      form.resetFields(['lowBalanceLevel']);
    }
  }, [form, currentLowBalanceEnabled]);

  const canEdit = auth.can(...businessAccountService.getBusinessAccountEditPermissions(businessAccount));

  return (
    <Modal
      title={lang.get('businessAccount.notifications.modal.title', { name: businessAccount.name })}
      caption={lang.get('businessAccount.notifications.modal.caption')}
      okText={lang.get('common.actions.save')}
      cancelText={!canEdit ? lang.get('common.actions.close') : null}
      okButtonProps={{ hidden: !canEdit }}
      width="small"
      open={open}
      confirmLoading={updateBusinessAccountDetailsMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        disabled={!canEdit}
        onFinish={handleSubmit}
      >
        <Form.Title>{lang.get('businessAccount.notifications.modal.lowBalanceTitle')}</Form.Title>
        <Form.SwitchItem name="lowBalanceEnabled" label={lang.get('businessAccount.notifications.modal.enable.label')}>
          <Switch />
        </Form.SwitchItem>
        <Form.Item
          className={styles.modal__amount}
          label={lang.get('businessAccount.notifications.modal.amount.label')}
          layout="horizontal"
          hidden={!currentLowBalanceEnabled}
        >
          <Space.Compact block>
            <Form.Item name="lowBalanceLevel" noStyle>
              <Input.Number placeholder={lang.get('businessAccount.notifications.modal.amount.placeholder')} />
            </Form.Item>
            <Form.Item name="lowBalanceCurrency" noStyle>
              <CurrencySelect className={styles.modal__currency} disabled />
            </Form.Item>
          </Space.Compact>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NotificationsModal;
