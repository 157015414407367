import React from 'react';

import { useLang } from 'hooks';
import { DashboardLayout } from 'layouts';
import { SinglePage } from 'components/layout';

import View from './View';

const BusinessAccountMembersPage = () => {
  const lang = useLang();

  return (
    <SinglePage.BusinessAccount render={(businessAccount) => (
      <DashboardLayout title={lang.get('businessAccount.member.list.title', { name: businessAccount.name })}>
        <View businessAccount={businessAccount} />
      </DashboardLayout>
    )} />
  );
};

export default BusinessAccountMembersPage;
