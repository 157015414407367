import React, { useEffect } from 'react';
import { createBrowserRouter, Link, RouterProvider } from 'react-router';

import { query } from 'data';
import { lang, url } from 'helpers';
import { useAuth, usePreloader } from 'hooks';
import { getBusinessAccountQuery } from 'hooks/queries';
import { PrivateRoute, ProtectedRoute, PublicRoute } from 'components/routes';
import { BusinessAccountExtended, BusinessAccountType, UserPermission } from 'types/models';

import {
  AuditLogsPage,
  AuthPage,
  BusinessAccountDailyBalancePage,
  BusinessAccountDailyRemittancePage,
  BusinessAccountDailyTransactionsPage,
  BusinessAccountFeeCommissionsPage,
  BusinessAccountMembersPage,
  BusinessAccountPage,
  BusinessAccountsDailyBalancePage,
  BusinessAccountsDailyRevenuePage,
  BusinessAccountsPage,
  BusinessAccountVirtualAccountsPage,
  BusinessApplicationsPage,
  ClientsPage,
  CorridorsPage,
  CorridorTypesPage,
  CostManagementPage,
  CurrenciesPage,
  DailyRemittancePage,
  DashboardPage,
  ErrorPage,
  FeeGroupsPage,
  FeeManagementPage,
  LoginPage,
  NotFoundPage,
  PasswordChangePage,
  PasswordResetPage,
  ProvidersDailyBalancePage,
  ProvidersDailySettlementPage,
  ProvidersPage,
  TransactionBundlesPage,
  TransactionsPage,
  UserRolesPage,
  UsersPage,
} from 'pages';

const router = createBrowserRouter([
  {
    errorElement: <ErrorPage />,
    children: [
      {
        path: url.toAuth(':hash'),
        element: <AuthPage />,
      }, {
        path: url.toPasswordChange(':hash'),
        element: <PasswordChangePage />,
      }, {
        path: url.toLogin(),
        element: (
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        ),
      }, {
        path: url.toPasswordReset(),
        element: (
          <PublicRoute>
            <PasswordResetPage />
          </PublicRoute>
        ),
      }, {
        path: url.toDashboard(),
        handle: {
          crumb: () => (
            <Link to={url.toDashboard()}>
              {lang.get('common.menu.dashboard')}
            </Link>
          ),
        },
        children: [
          {
            index: true,
            element: (
              <PrivateRoute>
                <DashboardPage />
              </PrivateRoute>
            ),
          }, {
            path: url.toClients(),
            element: (
              <ProtectedRoute permissions={[UserPermission.INDIVIDUALS_VIEW, UserPermission.INDIVIDUALS_UPDATE]}>
                <ClientsPage />
              </ProtectedRoute>
            ),
            handle: {
              crumb: () => (
                <Link to={url.toClients()}>
                  {lang.get('common.menu.clients')}
                </Link>
              ),
            },
          }, {
            path: url.toIndividuals(),
            handle: {
              crumb: () => (
                <Link to={url.toIndividuals()}>
                  {lang.get('common.menu.individuals')}
                </Link>
              ),
            },
            children: [
              {
                index: true,
                element: (
                  <ProtectedRoute permissions={[UserPermission.INDIVIDUALS_VIEW, UserPermission.INDIVIDUALS_UPDATE]}>
                    <BusinessAccountsPage type={BusinessAccountType.INDIVIDUAL} />
                  </ProtectedRoute>
                ),
              }, {
                path: url.toIndividual(':id'),
                loader: ({ params }) => query.ensureQueryData(getBusinessAccountQuery(params.id)),
                handle: {
                  crumb: (businessAccount: BusinessAccountExtended) => (
                    <Link to={url.toIndividual(businessAccount.id)}>
                      {businessAccount.name}
                    </Link>
                  ),
                },
                children: [
                  {
                    index: true,
                    element: (
                      <ProtectedRoute permissions={[UserPermission.INDIVIDUALS_VIEW, UserPermission.INDIVIDUALS_UPDATE]}>
                        <BusinessAccountPage />
                      </ProtectedRoute>
                    ),
                  }, {
                    path: url.toIndividualFeeCommissions(':id'),
                    element: (
                      <ProtectedRoute permissions={[UserPermission.INDIVIDUALS_VIEW, UserPermission.INDIVIDUALS_UPDATE]}>
                        <BusinessAccountFeeCommissionsPage />
                      </ProtectedRoute>
                    ),
                    loader: ({ params }) => query.ensureQueryData(getBusinessAccountQuery(params.id)),
                    handle: {
                      crumb: (businessAccount: BusinessAccountExtended) => (
                        <Link to={url.toIndividualFeeCommissions(businessAccount.id)}>
                          {lang.get('common.menu.feeCommissions')}
                        </Link>
                      ),
                    },
                  }, {
                    path: url.toIndividualMembers(':id'),
                    element: (
                      <ProtectedRoute permissions={[UserPermission.INDIVIDUALS_VIEW, UserPermission.INDIVIDUALS_UPDATE]}>
                        <BusinessAccountMembersPage />
                      </ProtectedRoute>
                    ),
                    loader: ({ params }) => query.ensureQueryData(getBusinessAccountQuery(params.id)),
                    handle: {
                      crumb: (businessAccount: BusinessAccountExtended) => (
                        <Link to={url.toIndividualMembers(businessAccount.id)}>
                          {lang.get('common.menu.members')}
                        </Link>
                      ),
                    },
                  }, {
                    path: url.toIndividualReports(':id'),
                    handle: {
                      crumb: () => <span>{lang.get('common.menu.reports')}</span>,
                    },
                    children: [
                      {
                        index: true,
                        element: <NotFoundPage />,
                      }, {
                        path: url.toIndividualDailyBalance(':id'),
                        element: (
                          <ProtectedRoute permissions={[UserPermission.INDIVIDUALS_REPORTS_VIEW]}>
                            <BusinessAccountDailyBalancePage />
                          </ProtectedRoute>
                        ),
                        loader: ({ params }) => query.ensureQueryData(getBusinessAccountQuery(params.id)),
                        handle: {
                          crumb: (businessAccount: BusinessAccountExtended) => (
                            <Link to={url.toIndividualDailyBalance(businessAccount.id)}>
                              {lang.get('common.menu.dailyBalance')}
                            </Link>
                          ),
                        },
                      }, {
                        path: url.toIndividualDailyRemittance(':id'),
                        element: (
                          <ProtectedRoute permissions={[UserPermission.INDIVIDUALS_REPORTS_VIEW]}>
                            <BusinessAccountDailyRemittancePage />
                          </ProtectedRoute>
                        ),
                        loader: ({ params }) => query.ensureQueryData(getBusinessAccountQuery(params.id)),
                        handle: {
                          crumb: (businessAccount: BusinessAccountExtended) => (
                            <Link to={url.toIndividualDailyRemittance(businessAccount.id)}>
                              {lang.get('common.menu.dailyRemittance')}
                            </Link>
                          ),
                        },
                      }, {
                        path: url.toIndividualDailyTransactions(':id'),
                        element: (
                          <ProtectedRoute permissions={[UserPermission.INDIVIDUALS_REPORTS_VIEW]}>
                            <BusinessAccountDailyTransactionsPage />
                          </ProtectedRoute>
                        ),
                        loader: ({ params }) => query.ensureQueryData(getBusinessAccountQuery(params.id)),
                        handle: {
                          crumb: (businessAccount: BusinessAccountExtended) => (
                            <Link to={url.toIndividualDailyTransactions(businessAccount.id)}>
                              {lang.get('common.menu.dailyTransactions')}
                            </Link>
                          ),
                        },
                      },
                    ],
                  }, {
                    path: url.toIndividualVirtualAccounts(':id'),
                    element: (
                      <ProtectedRoute permissions={[UserPermission.INDIVIDUALS_VIEW, UserPermission.INDIVIDUALS_UPDATE]}>
                        <BusinessAccountVirtualAccountsPage />
                      </ProtectedRoute>
                    ),
                    loader: ({ params }) => query.ensureQueryData(getBusinessAccountQuery(params.id)),
                    handle: {
                      crumb: (businessAccount: BusinessAccountExtended) => (
                        <Link to={url.toIndividualVirtualAccounts(businessAccount.id)}>
                          {lang.get('common.menu.virtualAccounts')}
                        </Link>
                      ),
                    },
                  },
                ],
              },
            ],
          }, {
            path: url.toBusinessAccounts(),
            handle: {
              crumb: () => (
                <Link to={url.toBusinessAccounts()}>
                  {lang.get('common.menu.businessAccounts')}
                </Link>
              ),
            },
            children: [
              {
                index: true,
                element: (
                  <ProtectedRoute permissions={[UserPermission.BA_VIEW, UserPermission.BA_UPDATE]}>
                    <BusinessAccountsPage type={BusinessAccountType.COMPANY} />
                  </ProtectedRoute>
                ),
              }, {
                path: url.toBusinessAccount(':id'),
                loader: ({ params }) => query.ensureQueryData(getBusinessAccountQuery(params.id)),
                handle: {
                  crumb: (businessAccount: BusinessAccountExtended) => (
                    <Link to={url.toBusinessAccount(businessAccount.id)}>
                      {businessAccount.name}
                    </Link>
                  ),
                },
                children: [
                  {
                    index: true,
                    element: (
                      <ProtectedRoute permissions={[UserPermission.BA_VIEW, UserPermission.BA_UPDATE]}>
                        <BusinessAccountPage />
                      </ProtectedRoute>
                    ),
                  }, {
                    path: url.toBusinessAccountFeeCommissions(':id'),
                    element: (
                      <ProtectedRoute permissions={[UserPermission.BA_VIEW, UserPermission.BA_UPDATE]}>
                        <BusinessAccountFeeCommissionsPage />
                      </ProtectedRoute>
                    ),
                    loader: ({ params }) => query.ensureQueryData(getBusinessAccountQuery(params.id)),
                    handle: {
                      crumb: (businessAccount: BusinessAccountExtended) => (
                        <Link to={url.toBusinessAccountFeeCommissions(businessAccount.id)}>
                          {lang.get('common.menu.feeCommissions')}
                        </Link>
                      ),
                    },
                  }, {
                    path: url.toBusinessAccountMembers(':id'),
                    element: (
                      <ProtectedRoute permissions={[UserPermission.BA_VIEW, UserPermission.BA_UPDATE]}>
                        <BusinessAccountMembersPage />
                      </ProtectedRoute>
                    ),
                    loader: ({ params }) => query.ensureQueryData(getBusinessAccountQuery(params.id)),
                    handle: {
                      crumb: (businessAccount: BusinessAccountExtended) => (
                        <Link to={url.toBusinessAccountMembers(businessAccount.id)}>
                          {lang.get('common.menu.members')}
                        </Link>
                      ),
                    },
                  }, {
                    path: url.toBusinessAccountReports(':id'),
                    handle: {
                      crumb: () => <span>{lang.get('common.menu.reports')}</span>,
                    },
                    children: [
                      {
                        index: true,
                        element: <NotFoundPage />,
                      }, {
                        path: url.toBusinessAccountDailyBalance(':id'),
                        element: (
                          <ProtectedRoute permissions={[UserPermission.BA_REPORTS_VIEW]}>
                            <BusinessAccountDailyBalancePage />
                          </ProtectedRoute>
                        ),
                        loader: ({ params }) => query.ensureQueryData(getBusinessAccountQuery(params.id)),
                        handle: {
                          crumb: (businessAccount: BusinessAccountExtended) => (
                            <Link to={url.toBusinessAccountDailyBalance(businessAccount.id)}>
                              {lang.get('common.menu.dailyBalance')}
                            </Link>
                          ),
                        },
                      }, {
                        path: url.toBusinessAccountDailyRemittance(':id'),
                        element: (
                          <ProtectedRoute permissions={[UserPermission.BA_REPORTS_VIEW]}>
                            <BusinessAccountDailyRemittancePage />
                          </ProtectedRoute>
                        ),
                        loader: ({ params }) => query.ensureQueryData(getBusinessAccountQuery(params.id)),
                        handle: {
                          crumb: (businessAccount: BusinessAccountExtended) => (
                            <Link to={url.toBusinessAccountDailyRemittance(businessAccount.id)}>
                              {lang.get('common.menu.dailyRemittance')}
                            </Link>
                          ),
                        },
                      }, {
                        path: url.toBusinessAccountDailyTransactions(':id'),
                        element: (
                          <ProtectedRoute permissions={[UserPermission.BA_REPORTS_VIEW]}>
                            <BusinessAccountDailyTransactionsPage />
                          </ProtectedRoute>
                        ),
                        loader: ({ params }) => query.ensureQueryData(getBusinessAccountQuery(params.id)),
                        handle: {
                          crumb: (businessAccount: BusinessAccountExtended) => (
                            <Link to={url.toBusinessAccountDailyTransactions(businessAccount.id)}>
                              {lang.get('common.menu.dailyTransactions')}
                            </Link>
                          ),
                        },
                      },
                    ],
                  }, {
                    path: url.toBusinessAccountVirtualAccounts(':id'),
                    element: (
                      <ProtectedRoute permissions={[UserPermission.BA_VIEW, UserPermission.BA_UPDATE]}>
                        <BusinessAccountVirtualAccountsPage />
                      </ProtectedRoute>
                    ),
                    loader: ({ params }) => query.ensureQueryData(getBusinessAccountQuery(params.id)),
                    handle: {
                      crumb: (businessAccount: BusinessAccountExtended) => (
                        <Link to={url.toBusinessAccountVirtualAccounts(businessAccount.id)}>
                          {lang.get('common.menu.virtualAccounts')}
                        </Link>
                      ),
                    },
                  },
                ],
              },
            ],
          }, {
            path: url.toBusinessApplications(),
            element: (
              <ProtectedRoute permissions={[UserPermission.BA_APPLICATIONS_VIEW, UserPermission.BA_APPLICATIONS_MGMT]}>
                <BusinessApplicationsPage />
              </ProtectedRoute>
            ),
            handle: {
              crumb: () => (
                <Link to={url.toBusinessApplications()}>
                  {lang.get('common.menu.businessApplications')}
                </Link>
              ),
            },
          }, {
            path: url.toTransactions(),
            handle: {
              crumb: () => (
                <Link to={url.toTransactions()}>
                  {lang.get('common.menu.transactions')}
                </Link>
              ),
            },
            children: [
              {
                index: true,
                element: (
                  <ProtectedRoute permissions={[UserPermission.TRX_VIEW, UserPermission.TRX_UPDATE]}>
                    <TransactionsPage />
                  </ProtectedRoute>
                ),
              }, {
                path: url.toTransactionBundles(),
                element: (
                  <ProtectedRoute permissions={[
                    UserPermission.TRX_BUNDLES_VIEW,
                    UserPermission.TRX_BUNDLES_MGMT,
                    UserPermission.TRX_BUNDLES_UPLOAD,
                  ]}>
                    <TransactionBundlesPage />
                  </ProtectedRoute>
                ),
                handle: {
                  crumb: () => (
                    <Link to={url.toTransactionBundles()}>
                      {lang.get('common.menu.transactionBundles')}
                    </Link>
                  ),
                },
              },
            ],
          }, {
            path: url.toReports(),
            handle: {
              crumb: () => <span>{lang.get('common.menu.reports')}</span>,
            },
            children: [
              {
                index: true,
                element: <NotFoundPage />,
              }, {
                path: url.toDailyRemittance(),
                element: (
                  <ProtectedRoute permissions={[UserPermission.REPORTS_VIEW]}>
                    <DailyRemittancePage />
                  </ProtectedRoute>
                ),
                handle: {
                  crumb: () => (
                    <Link to={url.toDailyRemittance()}>
                      {lang.get('common.menu.dailyRemittance')}
                    </Link>
                  ),
                },
              }, {
                path: url.toBusinessAccountsDailyBalance(),
                element: (
                  <ProtectedRoute permissions={[UserPermission.REPORTS_VIEW]}>
                    <BusinessAccountsDailyBalancePage />
                  </ProtectedRoute>
                ),
                handle: {
                  crumb: () => (
                    <Link to={url.toBusinessAccountsDailyBalance()}>
                      {lang.get('common.menu.businessAccountsDailyBalance')}
                    </Link>
                  ),
                },
              }, {
                path: url.toBusinessAccountsDailyRevenue(),
                element: (
                  <ProtectedRoute permissions={[UserPermission.REPORTS_VIEW]}>
                    <BusinessAccountsDailyRevenuePage />
                  </ProtectedRoute>
                ),
                handle: {
                  crumb: () => (
                    <Link to={url.toBusinessAccountsDailyRevenue()}>
                      {lang.get('common.menu.businessAccountsDailyRevenue')}
                    </Link>
                  ),
                },
              }, {
                path: url.toProvidersDailyBalance(),
                element: (
                  <ProtectedRoute permissions={[UserPermission.REPORTS_VIEW]}>
                    <ProvidersDailyBalancePage />
                  </ProtectedRoute>
                ),
                handle: {
                  crumb: () => (
                    <Link to={url.toProvidersDailyBalance()}>
                      {lang.get('common.menu.providersDailyBalance')}
                    </Link>
                  ),
                },
              }, {
                path: url.toProvidersDailySettlement(),
                element: (
                  <ProtectedRoute permissions={[UserPermission.REPORTS_VIEW]}>
                    <ProvidersDailySettlementPage />
                  </ProtectedRoute>
                ),
                handle: {
                  crumb: () => (
                    <Link to={url.toProvidersDailySettlement()}>
                      {lang.get('common.menu.providersDailySettlement')}
                    </Link>
                  ),
                },
              },
            ],
          }, {
            path: url.toSettings(),
            handle: {
              crumb: () => <span>{lang.get('common.menu.settings')}</span>,
            },
            children: [
              {
                index: true,
                element: <NotFoundPage />,
              }, {
                path: url.toAuditLogs(),
                element: (
                  <ProtectedRoute permissions={[UserPermission.AUDIT_LOGS_VIEW]}>
                    <AuditLogsPage />
                  </ProtectedRoute>
                ),
                handle: {
                  crumb: () => (
                    <Link to={url.toAuditLogs()}>
                      {lang.get('common.menu.auditLogs')}
                    </Link>
                  ),
                },
              }, {
                path: url.toCostManagement(),
                handle: {
                  crumb: () => (
                    <Link to={url.toCostManagement()}>
                      {lang.get('common.menu.costManagement')}
                    </Link>
                  ),
                },
                children: [{
                  index: true,
                  element: (
                    <ProtectedRoute permissions={[
                      UserPermission.COSTS_VIEW,
                      UserPermission.COSTS_UPDATE,
                      UserPermission.PROVIDERS_VIEW,
                      UserPermission.PROVIDERS_UPDATE,
                    ]}>
                      <CostManagementPage />
                    </ProtectedRoute>
                  ),
                }, {
                  path: url.toProviders(),
                  element: (
                    <ProtectedRoute permissions={[UserPermission.PROVIDERS_VIEW, UserPermission.PROVIDERS_UPDATE]}>
                      <ProvidersPage />
                    </ProtectedRoute>
                  ),
                  handle: {
                    crumb: () => (
                      <Link to={url.toProviders()}>
                        {lang.get('common.menu.providers')}
                      </Link>
                    ),
                  },
                }],
              }, {
                path: url.toCorridors(),
                handle: {
                  crumb: () => (
                    <Link to={url.toCorridors()}>
                      {lang.get('common.menu.corridors')}
                    </Link>
                  ),
                },
                children: [{
                  index: true,
                  element: (
                    <ProtectedRoute permissions={[
                      UserPermission.CURRENCY_CORRIDORS_VIEW,
                      UserPermission.CURRENCY_CORRIDORS_UPDATE,
                      UserPermission.CURRENCY_CORRIDOR_TRANSACTION_TYPES_VIEW,
                      UserPermission.CURRENCY_CORRIDOR_TRANSACTION_TYPES_UPDATE,
                    ]}>
                      <CorridorsPage />
                    </ProtectedRoute>
                  ),
                }, {
                  path: url.toCorridorTypes(),
                  element: (
                    <ProtectedRoute permissions={[
                      UserPermission.CURRENCY_CORRIDOR_TRANSACTION_TYPES_VIEW,
                      UserPermission.CURRENCY_CORRIDOR_TRANSACTION_TYPES_UPDATE,
                    ]}>
                      <CorridorTypesPage />
                    </ProtectedRoute>
                  ),
                  handle: {
                    crumb: () => (
                      <Link to={url.toCorridorTypes()}>
                        {lang.get('common.menu.corridorTypes')}
                      </Link>
                    ),
                  },
                }],
              }, {
                path: url.toCurrencies(),
                element: (
                  <ProtectedRoute permissions={[UserPermission.CURRENCY_RATES_VIEW, UserPermission.CURRENCY_RATES_UPDATE]}>
                    <CurrenciesPage />
                  </ProtectedRoute>
                ),
                handle: {
                  crumb: () => (
                    <Link to={url.toCurrencies()}>
                      {lang.get('common.menu.currencies')}
                    </Link>
                  ),
                },
              }, {
                path: url.toFeeManagement(),
                handle: {
                  crumb: () => (
                    <Link to={url.toFeeManagement()}>
                      {lang.get('common.menu.feeManagement')}
                    </Link>
                  ),
                },
                children: [{
                  index: true,
                  element: (
                    <ProtectedRoute permissions={[
                      UserPermission.FEE_COMMISSIONS_VIEW,
                      UserPermission.FEE_COMMISSIONS_UPDATE,
                      UserPermission.FEE_GROUPS_VIEW,
                      UserPermission.FEE_GROUPS_UPDATE,
                    ]}>
                      <FeeManagementPage />
                    </ProtectedRoute>
                  ),
                }, {
                  path: url.toFeeGroups(),
                  element: (
                    <ProtectedRoute permissions={[UserPermission.FEE_GROUPS_VIEW, UserPermission.FEE_GROUPS_UPDATE]}>
                      <FeeGroupsPage />
                    </ProtectedRoute>
                  ),
                  handle: {
                    crumb: () => (
                      <Link to={url.toFeeGroups()}>
                        {lang.get('common.menu.feeGroups')}
                      </Link>
                    ),
                  },
                }],
              }, {
                path: url.toUsers(),
                handle: {
                  crumb: () => (
                    <Link to={url.toUsers()}>
                      {lang.get('common.menu.users')}
                    </Link>
                  ),
                },
                children: [{
                  index: true,
                  element: (
                    <ProtectedRoute permissions={[
                      UserPermission.USERS_VIEW,
                      UserPermission.USERS_UPDATE,
                      UserPermission.USER_ROLES_VIEW,
                      UserPermission.USER_ROLES_UPDATE,
                    ]}>
                      <UsersPage />
                    </ProtectedRoute>
                  ),
                }, {
                  path: url.toUserRoles(),
                  element: (
                    <ProtectedRoute permissions={[UserPermission.USER_ROLES_VIEW, UserPermission.USER_ROLES_UPDATE]}>
                      <UserRolesPage />
                    </ProtectedRoute>
                  ),
                  handle: {
                    crumb: () => (
                      <Link to={url.toUserRoles()}>
                        {lang.get('common.menu.userRoles')}
                      </Link>
                    ),
                  },
                }],
              },
            ],
          },
        ],
      },
    ],
  },
]);

const AppProvider = () => {
  const auth = useAuth();
  const preloader = usePreloader();

  useEffect(() => {
    preloader.toggle(!auth.initialized);
  }, [auth.initialized, preloader]);

  return auth.initialized && <RouterProvider router={router} />;
};

const App = {
  Provider: AppProvider,
};

export default App;
