const config = {
  IS_PRODUCTION: process.env.NODE_ENV === 'production',
  NAME: process.env.REACT_APP_NAME as string,

  API_URL: process.env.REACT_APP_API_URL as string,
  API_TIMEOUT: 20000, // 20 seconds

  AUTH_KEEP_ALIVE_INTERVAL: 60000, // 1 minute
  AUTH_STORAGE_KEY: 'auth',

  COUNTRY_CODE_ANY: 'XX',
  CURRENCY_CODE_ANY: 'XXX',

  DEFAULT_COUNTRY: 'US',
  DEFAULT_CURRENCY: 'USD',
  DEFAULT_LOCALE: 'en',
  DEFAULT_PAGINATION_LIMIT: 7,

  DATE_FORMAT: 'MMM D, YYYY',
  DATE_RAW_FORMAT: 'YYYY-MM-DD',
  DATE_TIME_FORMAT: 'MMM D, YYYY LT',
  TIME_FORMAT: 'LT',

  THEME_MESSAGE_MAX_COUNT: 3,
  THEME_NOTIFICATION_DURATION: 5, // 5 seconds
  THEME_NOTIFICATION_MAX_COUNT: 3,

  BUSINESS_ACCOUNT_LOW_BALANCE_MIN: 0,
  BUSINESS_ACCOUNT_LOW_BALANCE_MAX: 1000000,
  BUSINESS_APPLICATION_ACTIVITY_MIN_LENGTH: 10,
  BUSINESS_APPLICATION_ACTIVITY_MAX_LENGTH: 250,
  BUSINESS_APPLICATION_NAME_MIN_LENGTH: 3,
  BUSINESS_APPLICATION_NAME_MAX_LENGTH: 50,
  CITY_MIN_LENGTH: 0,
  CITY_MAX_LENGTH: 64,
  CURRENCY_RATE_MIN: 0.000001,
  CURRENCY_RATE_MAX: 1000000,
  CURRENCY_RATE_PRECISION: 6,
  EMAIL_MAX_LENGTH: 50,
  FILE_MAX_SIZE: 5242880, // 5 MB
  FEE_COMMISSION_MIN: 0,
  FEE_COMMISSION_MAX: 1000000000,
  NAME_MAX_LENGTH: 50,
  PASSWORD_CURRENT_MIN_LENGTH: 2,
  PASSWORD_MIN_LENGTH: 8,
  PASSWORD_MAX_LENGTH: 50,
  PERCENT_MIN: 0,
  PERCENT_MAX: 100,
  PHONE_MAX_LENGTH: 20,
  PROVIDER_FEE_MIN: 0,
  PROVIDER_FEE_MAX: 1000000000,
  STATE_MIN_LENGTH: 2,
  STATE_MAX_LENGTH: 128,
  STREET_MIN_LENGTH: 2,
  STREET_MAX_LENGTH: 128,
  STRING_MIN_LENGTH: 3,
  STRING_MAX_LENGTH: 255,
  STRING_MD_MAX_LENGTH: 100,
  TABLE_ID_MAX_LENGTH: 7,
  TEXT_MIN_LENGTH: 8,
  TEXT_MAX_LENGTH: 1000,
  TOP_UP_MIN: 0.01,
  TOP_UP_MAX: 1000000000,
  TRANSACTION_BUNDLE_ALLOWED_EXTENSIONS: ['json'],
  VERIFICATION_CODE_LENGTH: 6,
  WEBSITE_MAX_LENGTH: 255,
  ZIP_MIN_LENGTH: 0,
  ZIP_MAX_LENGTH: 16,

  FILTERS_QUERY_PARAM: 'filters',
  MODAL_QUERY_PARAM: 'modal',

  AUDIT_LOGS_QUERY_KEY: 'audit-logs',
  AUDIT_LOG_QUERY_KEY: 'audit-log',
  AUTH_CHALLENGE_QUERY_KEY: 'auth-challenge',
  BUSINESS_ACCOUNTS_QUERY_KEY: 'business-accounts',
  BUSINESS_ACCOUNTS_BALANCE_QUERY_KEY: 'business-accounts-balance',
  BUSINESS_ACCOUNTS_BALANCES_QUERY_KEY: 'business-accounts-balances',
  BUSINESS_ACCOUNTS_DAILY_BALANCE_QUERY_KEY: 'business-accounts-daily-balance',
  BUSINESS_ACCOUNTS_DAILY_REVENUE_QUERY_KEY: 'business-accounts-daily-revenue',
  BUSINESS_ACCOUNTS_DAILY_REVENUE_TRANSACTIONS_QUERY_KEY: 'business-accounts-daily-revenue-transactions',
  BUSINESS_ACCOUNT_QUERY_KEY: 'business-account',
  BUSINESS_ACCOUNT_DAILY_BALANCE_QUERY_KEY: 'business-account-daily-balance',
  BUSINESS_ACCOUNT_DAILY_TRANSACTIONS_QUERY_KEY: 'business-account-daily-transactions',
  BUSINESS_ACCOUNT_DETAILS_QUERY_KEY: 'business-account-details',
  BUSINESS_ACCOUNT_FEE_COMMISSIONS_QUERY_KEY: 'business-account-fee-commissions',
  BUSINESS_ACCOUNT_MEMBERS_QUERY_KEY: 'business-account-members',
  BUSINESS_ACCOUNT_ROLES_QUERY_KEY: 'business-account-roles',
  BUSINESS_ACCOUNT_VIRTUAL_ACCOUNTS_QUERY_KEY: 'business-account-virtual-accounts',
  BUSINESS_APPLICATIONS_QUERY_KEY: 'business-applications',
  BUSINESS_APPLICATION_QUERY_KEY: 'business-application',
  BUSINESS_APPLICATION_DETAILS_QUERY_KEY: 'business-application-details',
  BUSINESS_APPLICATION_INDUSTRIES_QUERY_KEY: 'business-application-industries',
  BUSINESS_APPLICATION_KYB_DETAILS_QUERY_KEY: 'business-application-kyb-details',
  CLIENTS_QUERY_KEY: 'clients',
  CLIENT_QUERY_KEY: 'client',
  CLIENT_VERIFICATION_DETAILS_QUERY_KEY: 'client-verification-details',
  COSTS_QUERY_KEY: 'costs',
  CORRIDORS_QUERY_KEY: 'corridors',
  CORRIDOR_QUERY_KEY: 'corridor',
  CORRIDOR_TYPES_QUERY_KEY: 'corridor-types',
  CORRIDOR_TYPE_QUERY_KEY: 'corridor-type',
  COST_QUERY_KEY: 'cost',
  COUNTRIES_QUERY_KEY: 'countries',
  CURRENCIES_QUERY_KEY: 'currencies',
  CURRENCY_RATES_QUERY_KEY: 'currency-rates',
  DAILY_REMITTANCE_QUERY_KEY: 'daily-remittance',
  FEE_COMMISSIONS_QUERY_KEY: 'fee-commissions',
  FEE_COMMISSION_QUERY_KEY: 'fee-commission',
  FEE_GROUPS_QUERY_KEY: 'fee-groups',
  FEE_GROUP_QUERY_KEY: 'fee-group',
  PROFILE_QUERY_KEY: 'profile',
  PROFILE_TOPICS_QUERY_KEY: 'profile-topics',
  PROVIDERS_QUERY_KEY: 'providers',
  PROVIDERS_DAILY_BALANCE_QUERY_KEY: 'providers-daily-balance',
  PROVIDERS_DAILY_SETTLEMENT_QUERY_KEY: 'providers-daily-settlement',
  PROVIDERS_DAILY_SETTLEMENT_BREAKDOWN_QUERY_KEY: 'providers-daily-settlement-breakdown',
  PROVIDERS_DAILY_SETTLEMENT_TRANSACTIONS_QUERY_KEY: 'providers-daily-settlement-transactions',
  PROVIDER_QUERY_KEY: 'provider',
  TRANSACTIONS_QUERY_KEY: 'transactions',
  TRANSACTION_QUERY_KEY: 'transaction',
  TRANSACTION_BUNDLES_QUERY_KEY: 'transaction-bundles',
  TRANSACTION_BUNDLE_QUERY_KEY: 'transaction-bundle',
  USERS_QUERY_KEY: 'users',
  USER_QUERY_KEY: 'user',
  USER_ROLES_QUERY_KEY: 'user-roles',
  USER_ROLE_QUERY_KEY: 'user-role',
  USER_TOPICS_QUERY_KEY: 'user-topics',
};

export default config;
