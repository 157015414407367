import React from 'react';

import { useLang } from 'hooks';
import { DashboardLayout } from 'layouts';
import { BusinessAccountIcon, SinglePage } from 'components/layout';
import { DailyRemittanceView } from 'components/views';

const BusinessAccountDailyRemittancePage = () => {
  const lang = useLang();

  return (
    <SinglePage.BusinessAccount render={(businessAccount) => (
      <DashboardLayout title={lang.get('businessAccount.dailyRemittance.title', { name: businessAccount.name })}>
        <DailyRemittanceView
          icon={<BusinessAccountIcon businessAccountType={businessAccount.type} size="large" />}
          title={lang.get('businessAccount.dailyRemittance.title', { name: businessAccount.name })}
          businessAccount={businessAccount}
        />
      </DashboardLayout>
    )} />
  );
};

export default BusinessAccountDailyRemittancePage;
