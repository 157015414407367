import React from 'react';

import { config } from 'data';
import { businessAccountService } from 'services';
import { useLang } from 'hooks';
import { DashboardLayout } from 'layouts';
import { BusinessAccountIcon, SinglePage } from 'components/layout';
import { FeeCommissionsView } from 'components/views';

const BusinessAccountFeeCommissionsPage = () => {
  const lang = useLang();

  return (
    <SinglePage.BusinessAccount render={(businessAccount) => (
      <DashboardLayout title={lang.get('businessAccount.feeCommission.list.title', { name: businessAccount.name })}>
        <FeeCommissionsView
          icon={<BusinessAccountIcon businessAccountType={businessAccount.type} size="large" />}
          title={lang.get('businessAccount.feeCommission.list.title', { name: businessAccount.name })}
          tableKey={[config.BUSINESS_ACCOUNT_FEE_COMMISSIONS_QUERY_KEY, businessAccount.id]}
          fetchData={(params) => businessAccountService.getBusinessAccountFeeCommissions(businessAccount.id, params)}
        />
      </DashboardLayout>
    )} />
  );
};

export default BusinessAccountFeeCommissionsPage;
