import { useCallback, useMemo } from 'react';

import { config } from 'data';
import { useAllCountriesQuery } from 'hooks/queries';
import { Nullish } from 'types/common';
import { CountryCode } from 'types/models';

import useLang from './lang';

const useCountryFormatter = () => {
  const lang = useLang();

  const countriesQuery = useAllCountriesQuery();

  const format = useCallback((code: Nullish<CountryCode>, fallback?: string) => {
    if (!code) {
      return fallback;
    }

    if (code === config.COUNTRY_CODE_ANY) {
      return lang.get('common.actions.any');
    }

    return countriesQuery.data?.find((country) => country.code === code)?.name ?? fallback;
  }, [lang, countriesQuery.data]);

  return useMemo(() => ({ format }), [format]);
};

export default useCountryFormatter;
