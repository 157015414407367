import React, { useState } from 'react';

import { config } from 'data';
import { useCorridorQuery, useCorridorsQuery } from 'hooks/queries';
import { Plus } from 'components/icons';
import { Can, TableView } from 'components/layout';
import { CountrySelect, CurrencySelect } from 'components/selects';
import { Button, Form } from 'components/ui';
import { Uuid } from 'types/common';
import { TableColumns } from 'types/components';
import { Corridor, UserPermission } from 'types/models';
import { CorridorsParams } from 'types/services';

import {
  useCountryFormatter,
  useCurrencyFormatter,
  useLang,
  useModal,
  useQueryModal,
  useTable,
  useTableQuery,
} from 'hooks';

import CreateModal from './CreateModal';
import ViewModal from './ViewModal';

type TableParams = CorridorsParams;

const initialTableParams: TableParams = {
  //
};

const CorridorsView = () => {
  const countryFormatter = useCountryFormatter();
  const currencyFormatter = useCurrencyFormatter();
  const lang = useLang();
  const createModal = useModal();
  const table = useTable<Corridor, TableParams>([config.CORRIDORS_QUERY_KEY], initialTableParams);

  const [corridorId, setCorridorId] = useState<Uuid>();

  const viewModal = useQueryModal(config.CORRIDORS_QUERY_KEY, setCorridorId);

  const corridorQuery = useCorridorQuery(corridorId);

  const corridorsQuery = useCorridorsQuery({
    page: table.page,
    country: table.params.country || undefined,
    currency: table.params.currency || undefined,
  });

  const handleCreateClick = () => {
    createModal.show();
  };

  const handleViewClick = (corridor: Corridor) => () => {
    viewModal.show(corridor.id);
  };

  useTableQuery(table, corridorsQuery);

  const columns: TableColumns<Corridor> = [
    {
      key: 'country',
      title: lang.get('corridor.list.country'),
      render: (_, corridor) => countryFormatter.format(corridor.country),
    }, {
      key: 'currency',
      title: lang.get('corridor.list.currency'),
      render: (_, corridor) => currencyFormatter.format(corridor.currency),
    },
  ];

  return (
    <Can permissions={[UserPermission.CURRENCY_CORRIDORS_VIEW, UserPermission.CURRENCY_CORRIDORS_UPDATE]}>
      <TableView
        title={lang.get('corridor.list.title')}
        actions={(
          <Can permissions={[UserPermission.CURRENCY_CORRIDORS_UPDATE]}>
            <Button icon={<Plus />} onClick={handleCreateClick}>
              {lang.get('corridor.list.create')}
            </Button>
          </Can>
        )}
      >

        <TableView.Filters<TableParams>
          initialValues={initialTableParams}
          values={table.params}
          inline
          onSubmit={table.setParams}
        >
          <Form.Item name="country">
            <CountrySelect
              placeholder={lang.get('common.filters.countries')}
              allowClear
              includeAny
              popupMatchSelectWidth={false}
            />
          </Form.Item>
          <Form.Item name="currency">
            <CurrencySelect
              placeholder={lang.get('common.filters.currencies')}
              allowClear
              includeAny
              popupMatchSelectWidth={false}
            />
          </Form.Item>
        </TableView.Filters>

        <TableView.Table<Corridor>
          table={table}
          columns={columns}
          rowKey={(corridor) => corridor.id}
          loading={corridorsQuery.isFetching}
          clickable
          onRow={(corridor) => ({ onClick: handleViewClick(corridor) })}
        />

        <CreateModal
          open={createModal.open}
          onClose={createModal.hide}
        />

        {corridorQuery.data && (
          <ViewModal
            corridor={corridorQuery.data}
            open={!corridorQuery.isFetching && viewModal.open}
            onClose={viewModal.hide}
          />
        )}

      </TableView>
    </Can>
  );
};

export default CorridorsView;
