import React, { FC, useEffect } from 'react';

import { config, moment, yup } from 'data';
import { corridorTypeService, feeCommissionService, transactionService } from 'services';
import { useAuth, useCorridorFormatter, useForm, useFormWatch, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { useAllCorridorTypesQuery, useAllFeeGroupsQuery } from 'hooks/queries';
import { Trash } from 'components/icons';
import { CurrencySelect } from 'components/selects';
import { DateRangePicker, Form, Input, Modal, PopconfirmButton, Select, TextArea } from 'components/ui';
import { DateRangePickerValue, ModalBaseProps } from 'types/components';
import { FeeCommissionParams } from 'types/services';

import {
  FeeCommission,
  FeeCommissionFrequency,
  FeeCommissionLevel,
  TransactionDirection,
  TransactionType,
  UserPermission,
} from 'types/models';

type FormValues = FeeCommissionParams & {
  transactionType: TransactionType;
  period?: DateRangePickerValue;
};

const directions = Object.values(TransactionDirection);
const frequencies = Object.values(FeeCommissionFrequency);
const levels = Object.values(FeeCommissionLevel);
const transactionTypes = Object.values(TransactionType);
const transactionTypeDirections = transactionService.getTransactionTypeDirections();
const transactionTypeFrequencies = transactionService.getTransactionTypeFrequencies();

const initialValues: Omit<
  FormValues,
  | 'level'
  | 'transactionType'
  | 'fixedTransactionFee'
  | 'percentTransactionFee'
  | 'minTransactionFeeAmount'
  | 'maxTransactionFeeAmount'
  | 'fxMarkup'
> = {
  corridorId: '',
  name: '',
  direction: null,
  feeFrequency: null,
  transactionFeeCurrency: config.DEFAULT_CURRENCY,
};

const validationSchema = yup.object().shape({
  corridorId: yup.string().required().uuid(),
  feeGroupId: yup
    .string()
    .notRequired()
    .uuid()
    .when('level', ([level], schema) => level === FeeCommissionLevel.GROUP ? schema.required() : schema),
  name: yup.string().required().trim().max(config.STRING_MAX_LENGTH),
  level: yup.string().required().oneOf(levels),
  transactionType: yup.string().required().oneOf(transactionTypes),
  direction: yup
    .string()
    .required()
    .oneOf(directions)
    .default(initialValues.direction)
    .when('transactionType', ([type], schema) => {
      if (type && !transactionTypeDirections[type as TransactionType].length) {
        return schema.notRequired();
      }

      return schema;
    }),
  feeFrequency: yup
    .string()
    .required()
    .oneOf(frequencies)
    .default(initialValues.feeFrequency)
    .when('transactionType', ([type], schema) => {
      if (type && !transactionTypeFrequencies[type as TransactionType].length) {
        return schema.notRequired();
      }

      return schema;
    }),
  transactionFeeCurrency: yup.string().required().currency(),
  fixedTransactionFee: yup
    .number()
    .required()
    .decimal()
    .min(config.FEE_COMMISSION_MIN)
    .max(config.FEE_COMMISSION_MAX)
    .transform((value) => !isNaN(value) ? value : null),
  percentTransactionFee: yup
    .number()
    .required()
    .decimal()
    .percent()
    .transform((value) => !isNaN(value) ? value : null)
    .when(['transactionType'], ([type], schema) => {
      if (type && !transactionService.isTransactionTypeSupportsPercentFee(type)) {
        return schema.notRequired();
      }

      return schema;
    }),
  fxMarkup: yup
    .number()
    .required()
    .percent()
    .decimal()
    .transform((value) => value || 0),
  minTransactionFeeAmount: yup
    .number()
    .required()
    .decimal()
    .min(config.FEE_COMMISSION_MIN)
    .max(config.FEE_COMMISSION_MAX)
    .transform((value) => value || 0)
    .when(['fixedTransactionFee', 'percentTransactionFee', 'maxTransactionFeeAmount'], ([fixedFee, percentFee, maxFee], schema) => {
      if (!percentFee) {
        return schema.notRequired();
      }

      if (fixedFee) {
        schema = schema.min(fixedFee);
      }

      if (maxFee) {
        schema = schema.max(maxFee);
      }

      return schema;
    }),
  maxTransactionFeeAmount: yup
    .number()
    .required()
    .decimal()
    .min(config.FEE_COMMISSION_MIN)
    .max(config.FEE_COMMISSION_MAX)
    .transform((value) => value || 0)
    .when(['percentTransactionFee', 'minTransactionFeeAmount'], ([percentFee, minFee], schema) => {
      if (!percentFee) {
        return schema.notRequired();
      }

      if (minFee) {
        schema = schema.min(minFee);
      }

      return schema;
    }),
  description: yup.string().notRequired().trim().max(config.TEXT_MAX_LENGTH),
}, [['minTransactionFeeAmount', 'maxTransactionFeeAmount']]);

type FeeCommissionModalProps = ModalBaseProps & {
  feeCommission?: FeeCommission;
};

const FeeCommissionModal: FC<FeeCommissionModalProps> = ({
  feeCommission,
  open,
  onClose,
}) => {
  const auth = useAuth();
  const corridorFormatter = useCorridorFormatter();
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const currentLevel = useFormWatch('level', form);
  const currentTransactionType = useFormWatch('transactionType', form);
  const currentTransactionFeeCurrency = useFormWatch('transactionFeeCurrency', form);

  const isGroupLevel = currentLevel === FeeCommissionLevel.GROUP;
  const feeCommissionId = feeCommission?.id ?? '';
  const feeCommissionName = feeCommission?.name ?? '';

  const corridorTypesQuery = useAllCorridorTypesQuery();
  const feeGroupsQuery = useAllFeeGroupsQuery();

  const invalidateFeeCommissionQueries = async () => {
    await queryInvalidate([config.BUSINESS_ACCOUNT_FEE_COMMISSIONS_QUERY_KEY]);
    await queryInvalidate([config.FEE_COMMISSIONS_QUERY_KEY]);
    await queryInvalidate([config.FEE_COMMISSION_QUERY_KEY, feeCommissionId]);
    await queryInvalidate([config.FEE_GROUPS_QUERY_KEY]);
  };

  const createFeeCommissionMutation = useMutation({
    mutationFn: feeCommissionService.createFeeCommission,
    onSuccess: invalidateFeeCommissionQueries,
    successNotification: lang.get('feeCommission.modal.createSuccess'),
  });

  const updateFeeCommissionMutation = useMutation({
    mutationFn: (values: FormValues) => feeCommissionService.updateFeeCommission(feeCommissionId, values),
    onSuccess: invalidateFeeCommissionQueries,
    successNotification: lang.get('feeCommission.modal.updateSuccess', { name: feeCommissionName }),
  });

  const activateFeeCommissionMutation = useMutation({
    mutationFn: () => feeCommissionService.activateFeeCommission(feeCommissionId),
    onSuccess: invalidateFeeCommissionQueries,
    successNotification: lang.get('feeCommission.modal.activateSuccess', { name: feeCommissionName }),
  });

  const deactivateFeeCommissionMutation = useMutation({
    mutationFn: () => feeCommissionService.deactivateFeeCommission(feeCommissionId),
    onSuccess: invalidateFeeCommissionQueries,
    successNotification: lang.get('feeCommission.modal.deactivateSuccess', { name: feeCommissionName }),
  });

  const deleteFeeCommissionMutation = useMutation({
    mutationFn: () => feeCommissionService.deleteFeeCommission(feeCommissionId),
    onSuccess: invalidateFeeCommissionQueries,
    successNotification: lang.get('feeCommission.modal.deleteSuccess', { name: feeCommissionName }),
  });

  const handleSubmit = async (values: FormValues) => {
    const [startDate, endDate] = values.period ?? [];

    values.startDate = startDate ? moment(startDate).startOf('day').toISOString() : null;
    values.endDate = endDate ? moment(endDate).endOf('day').toISOString() : null;

    delete values.period;

    feeCommission
      ? await updateFeeCommissionMutation.mutateAsync(values)
      : await createFeeCommissionMutation.mutateAsync(values);

    onClose();
  };

  const handleActivate = async () => {
    await activateFeeCommissionMutation.mutateAsync();

    onClose();
  };

  const handleDeactivate = async () => {
    await deactivateFeeCommissionMutation.mutateAsync();

    onClose();
  };

  const handleDelete = async () => {
    await deleteFeeCommissionMutation.mutateAsync();

    onClose();
  };

  useEffect(() => {
    if (open && feeCommission) {
      form.setFieldsValue({
        ...feeCommission,
        corridorId: feeCommission.corridor.id,
        transactionType: feeCommission.corridor.type,
        period: [
          feeCommission.startDate && moment(feeCommission.startDate),
          feeCommission.endDate && moment(feeCommission.endDate),
        ],
      });
    }
  }, [feeCommission, open, form]);

  useEffect(() => {
    if (form.isFieldTouched('level')) {
      form.resetFields(['feeGroupId']);
    }
  }, [form, isGroupLevel]);

  useEffect(() => {
    if (form.isFieldTouched('transactionType')) {
      const directions = transactionTypeDirections[currentTransactionType];

      if (directions.length === 1) {
        form.setFieldsValue({ direction: directions[0] });
      } else {
        form.resetFields(['direction']);
      }

      const frequencies = transactionTypeFrequencies[currentTransactionType];

      if (frequencies.length === 1) {
        form.setFieldsValue({ feeFrequency: frequencies[0] });
      } else {
        form.resetFields(['feeFrequency']);
      }

      if (!transactionService.isTransactionTypeSupportsFixedFee(currentTransactionType)) {
        form.resetFields(['fixedTransactionFee']);
      }

      if (!transactionService.isTransactionTypeSupportsPercentFee(currentTransactionType)) {
        form.resetFields(['percentTransactionFee', 'minTransactionFeeAmount', 'maxTransactionFeeAmount']);
      }

      form.resetFields(['corridorId']);
    }
  }, [form, currentTransactionType]);

  const canEdit = auth.can(UserPermission.FEE_COMMISSIONS_UPDATE);
  const isEditing = Boolean(feeCommission);
  const hasActions = canEdit && isEditing;
  const corridorTypeGroups = corridorTypeService.groupTypes(corridorTypesQuery.data ?? []);

  return (
    <Modal
      title={
        isEditing
          ? lang.get('feeCommission.modal.updateTitle', { name: feeCommissionName })
          : lang.get('feeCommission.modal.createTitle')
      }
      caption={
        isEditing
          ? lang.get('feeCommission.modal.updateCaption')
          : lang.get('feeCommission.modal.createCaption')
      }
      okText={isEditing ? lang.get('common.actions.save') : lang.get('common.actions.create')}
      cancelText={!canEdit ? lang.get('common.actions.close') : null}
      okButtonProps={{ hidden: !canEdit }}
      extraActions={hasActions && (
        <PopconfirmButton
          title={lang.get('feeCommission.modal.deleteTitle')}
          icon={<Trash />}
          danger
          loading={deleteFeeCommissionMutation.isPending}
          onConfirm={handleDelete}
        >
          {lang.get('common.actions.delete')}
        </PopconfirmButton>
      )}
      width="small"
      open={open}
      confirmLoading={createFeeCommissionMutation.isPending || updateFeeCommissionMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        disabled={!canEdit}
        onFinish={handleSubmit}
      >

        <Form.ActionsItem hidden={!hasActions}>
          {feeCommission?.active ? (
            <PopconfirmButton
              title={lang.get('feeCommission.modal.deactivateTitle')}
              danger
              loading={deactivateFeeCommissionMutation.isPending}
              onConfirm={handleDeactivate}
            >
              {lang.get('common.actions.deactivate')}
            </PopconfirmButton>
          ) : (
            <PopconfirmButton
              title={lang.get('feeCommission.modal.activateTitle')}
              type="primary"
              ghost
              loading={activateFeeCommissionMutation.isPending}
              onConfirm={handleActivate}
            >
              {lang.get('common.actions.activate')}
            </PopconfirmButton>
          )}
        </Form.ActionsItem>

        <Form.Divider hidden={!hasActions} />

        <Form.Item name="transactionFeeCurrency" hidden>
          <CurrencySelect disabled />
        </Form.Item>
        <Form.Item name="name" label={lang.get('common.form.name.label')}>
          <Input placeholder={lang.get('common.form.name.placeholder')} />
        </Form.Item>
        <Form.Item name="level" label={lang.get('feeCommission.modal.level.label')}>
          <Select
            placeholder={lang.get('feeCommission.modal.level.placeholder')}
            options={levels.map((level) => ({
              value: level,
              label: lang.get(`feeCommission.levels.${level.toLowerCase()}`),
            }))}
          />
        </Form.Item>
        <Form.Item
          name="feeGroupId"
          label={lang.get('feeCommission.modal.group.label')}
          hidden={!isGroupLevel}
        >
          <Select
            placeholder={lang.get('feeCommission.modal.group.placeholder')}
            options={feeGroupsQuery.data?.map((group) => ({
              value: group.id,
              label: group.name,
            }))}
            loading={feeGroupsQuery.isFetching}
          />
        </Form.Item>
        <Form.Item name="transactionType" label={lang.get('feeCommission.modal.transactionType.label')}>
          <Select
            placeholder={lang.get('feeCommission.modal.transactionType.placeholder')}
            options={Object.keys(corridorTypeGroups).map((type) => ({
              value: type,
              label: lang.get(`transaction.types.${type.toLowerCase()}`),
            }))}
          />
        </Form.Item>
        <Form.Item name="corridorId" label={lang.get('feeCommission.modal.corridor.label')}>
          <Select
            placeholder={lang.get('feeCommission.modal.corridor.placeholder')}
            options={corridorTypeGroups[currentTransactionType]?.map((corridorType) => ({
              value: corridorType.id,
              label: corridorFormatter.format(corridorType.currencyCorridor.country, corridorType.currencyCorridor.currency),
            }))}
            disabled={!canEdit || !currentTransactionType}
          />
        </Form.Item>
        <Form.Item
          name="direction"
          label={lang.get('feeCommission.modal.direction.label')}
          hidden={currentTransactionType && !transactionTypeDirections[currentTransactionType].length}
        >
          <Select
            placeholder={lang.get('feeCommission.modal.direction.placeholder')}
            options={directions.map((direction) => ({
              value: direction,
              label: lang.get(`transaction.directions.${direction.toLowerCase()}`),
              disabled: Boolean(currentTransactionType && !transactionTypeDirections[currentTransactionType].includes(direction)),
            }))}
            disabled={!canEdit || !currentTransactionType}
          />
        </Form.Item>
        <Form.Item
          name="feeFrequency"
          label={lang.get('feeCommission.modal.frequency.label')}
          hidden={currentTransactionType && !transactionTypeFrequencies[currentTransactionType].length}
        >
          <Select
            placeholder={lang.get('feeCommission.modal.frequency.placeholder')}
            options={frequencies.map((frequency) => ({
              value: frequency,
              label: lang.get(`feeCommission.frequencies.${frequency.toLowerCase()}`),
              disabled: Boolean(currentTransactionType && !transactionTypeFrequencies[currentTransactionType].includes(frequency)),
            }))}
            disabled={!canEdit || !canEdit || !currentTransactionType}
          />
        </Form.Item>
        <Form.Item
          name="fixedTransactionFee"
          label={lang.get('feeCommission.modal.fixedTransactionFee.label')}
          hidden={currentTransactionType && !transactionService.isTransactionTypeSupportsFixedFee(currentTransactionType)}
        >
          <Input.Number
            placeholder={lang.get('feeCommission.modal.fixedTransactionFee.placeholder')}
            suffix={currentTransactionFeeCurrency ?? ' '}
            disabled={!canEdit || !currentTransactionType}
          />
        </Form.Item>
        <Form.Item
          name="percentTransactionFee"
          label={lang.get('feeCommission.modal.percentTransactionFee.label')}
          hidden={currentTransactionType && !transactionService.isTransactionTypeSupportsPercentFee(currentTransactionType)}
        >
          <Input.Number
            placeholder={lang.get('feeCommission.modal.percentTransactionFee.placeholder')}
            suffix="%"
            disabled={!canEdit || !currentTransactionType}
          />
        </Form.Item>
        <Form.Columns hidden={currentTransactionType && !transactionService.isTransactionTypeSupportsPercentFee(currentTransactionType)}>
          <Form.Item name="minTransactionFeeAmount" label={lang.get('feeCommission.modal.minTransactionFeeAmount.label')}>
            <Input.Number
              placeholder={lang.get('feeCommission.modal.minTransactionFeeAmount.placeholder')}
              suffix={currentTransactionFeeCurrency ?? ' '}
              disabled={!canEdit || !currentTransactionType}
            />
          </Form.Item>
          <Form.Item name="maxTransactionFeeAmount" label={lang.get('feeCommission.modal.maxTransactionFeeAmount.label')}>
            <Input.Number
              placeholder={lang.get('feeCommission.modal.maxTransactionFeeAmount.placeholder')}
              suffix={currentTransactionFeeCurrency ?? ' '}
              disabled={!canEdit || !currentTransactionType}
            />
          </Form.Item>
        </Form.Columns>
        <Form.Item name="fxMarkup" label={lang.get('feeCommission.modal.fxMarkup.label')}>
          <Input.Number placeholder={lang.get('feeCommission.modal.fxMarkup.placeholder')} suffix="%" />
        </Form.Item>
        <Form.Item name="period" label={lang.get('feeCommission.modal.period.label')}>
          <DateRangePicker minDate={moment().startOf('day')} />
        </Form.Item>
        <Form.Item name="description" label={lang.get('common.form.description.label')}>
          <TextArea placeholder={lang.get('common.form.description.placeholder')} />
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default FeeCommissionModal;
