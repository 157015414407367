import React, { FC, useEffect } from 'react';

import { config } from 'data';
import { businessAccountService, virtualAccountService } from 'services';
import { useAuth, useLang, useModal, useMutation, useQueryInvalidate } from 'hooks';
import { VirtualAccountStatus } from 'components/layout';
import { Descriptions, Form, Modal, PopconfirmButton } from 'components/ui';
import { ModalBaseProps } from 'types/components';

import {
  BusinessAccount,
  BusinessAccountVirtualAccount,
  VirtualAccountStatus as EVirtualAccountStatus,
} from 'types/models';

type VirtualAccountModalProps = ModalBaseProps & {
  businessAccount: BusinessAccount;
  virtualAccount: BusinessAccountVirtualAccount;
};

const VirtualAccountModal: FC<VirtualAccountModalProps> = ({
  businessAccount,
  virtualAccount,
  open,
  onClose,
}) => {
  const auth = useAuth();
  const lang = useLang();
  const rolesModal = useModal();
  const queryInvalidate = useQueryInvalidate();

  const invalidateVirtualAccountQueries = async () => {
    await queryInvalidate([config.BUSINESS_ACCOUNT_VIRTUAL_ACCOUNTS_QUERY_KEY, businessAccount.id]);
  };

  const approveVirtualAccountMutation = useMutation({
    mutationFn: () => virtualAccountService.approveVirtualAccount(virtualAccount.id),
    onSuccess: invalidateVirtualAccountQueries,
    successNotification: lang.get('businessAccount.virtualAccount.modal.approveSuccess', { name: virtualAccount.title }),
  });

  const rejectVirtualAccountMutation = useMutation({
    mutationFn: () => virtualAccountService.rejectVirtualAccount(virtualAccount.id),
    onSuccess: invalidateVirtualAccountQueries,
    successNotification: lang.get('businessAccount.virtualAccount.modal.rejectSuccess', { name: virtualAccount.title }),
  });

  const handleApprove = async () => {
    await approveVirtualAccountMutation.mutateAsync();

    onClose();
  };

  const handleReject = async () => {
    await rejectVirtualAccountMutation.mutateAsync();

    onClose();
  };

  useEffect(() => {
    if (!open) {
      rolesModal.hide();
    }
  }, [open, rolesModal]);

  const canEdit = auth.can(...businessAccountService.getBusinessAccountEditPermissions(businessAccount));
  const hasActions = canEdit && virtualAccount.externalStatus === EVirtualAccountStatus.PENDING;

  return (
    <Modal
      title={lang.get('businessAccount.virtualAccount.modal.title', { name: virtualAccount.title })}
      caption={lang.get('businessAccount.virtualAccount.modal.caption')}
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      width="small"
      open={open}
      onCancel={onClose}
    >

      <Form.ActionsItem hidden={!hasActions}>
        <PopconfirmButton
          title={lang.get('businessAccount.virtualAccount.modal.approveTitle')}
          type="primary"
          ghost
          loading={approveVirtualAccountMutation.isPending}
          onConfirm={handleApprove}
        >
          {lang.get('common.actions.approve')}
        </PopconfirmButton>
        <PopconfirmButton
          title={lang.get('businessAccount.virtualAccount.modal.rejectTitle')}
          danger
          loading={rejectVirtualAccountMutation.isPending}
          onConfirm={handleReject}
        >
          {lang.get('common.actions.reject')}
        </PopconfirmButton>
      </Form.ActionsItem>

      <Form.Divider hidden={!hasActions} />

      <Descriptions
        column={1}
        items={[{
          label: lang.get('businessAccount.virtualAccount.modal.id.label'),
          children: virtualAccount.id,
        }, {
          label: lang.get('businessAccount.virtualAccount.modal.number.label'),
          children: virtualAccount.externalId,
        }, {
          label: lang.get('common.form.status.label'),
          children: <VirtualAccountStatus status={virtualAccount.externalStatus} />,
        }]}
      />

    </Modal>
  );
};

export default VirtualAccountModal;
