import React, { FC, useCallback, useMemo, useState } from 'react';
import map from 'lodash/map';

import { config, moment } from 'data';
import { formatter } from 'helpers';
import { reportService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useAuth, useLang, useModal, useMutation, useQuery, useQueryInvalidate, useTable, useTableQuery } from 'hooks';
import { BusinessAccountIcon, TableView } from 'components/layout';
import { Modal, PopconfirmButton, Table } from 'components/ui';
import { ExportColumns, ModalBaseProps, TableColumns } from 'types/components';
import { ReportProvidersDailySettlementItem, UserPermission } from 'types/models';
import { ReportProvidersDailySettlementParams } from 'types/services';

import styles from './styles.module.css';

import SettlementStatus from './Status';
import TransactionsModal from './TransactionsModal';

const EXPORT_FILE_NAME = 'report-providers-daily-settlement-breakdown';

type SettlementBreakdownModalProps = ModalBaseProps & {
  item: ReportProvidersDailySettlementItem;
};

const SettlementBreakdownModal: FC<SettlementBreakdownModalProps> = ({ item, open, onClose }) => {
  const auth = useAuth();
  const lang = useLang();
  const modal = useModal();
  const table = useTable<ReportProvidersDailySettlementItem>([config.PROVIDERS_DAILY_SETTLEMENT_BREAKDOWN_QUERY_KEY]);
  const queryInvalidate = useQueryInvalidate();

  const [selectedItem, setSelectedItem] = useState<ReportProvidersDailySettlementItem>();

  const selectedTransactionIds = map(table.selectedData, 'transactionIds').flat();

  const reportParams: ReportProvidersDailySettlementParams = {
    page: table.page,
    providerId: item.provider.id,
    currency: item.currency,
    includeClientInfo: true,
    dateFrom: moment(item.date).startOf('day').toISOString(),
    dateTo: moment(item.date).endOf('day').toISOString(),
  };

  const reportQuery = useQuery({
    queryKey: [config.PROVIDERS_DAILY_SETTLEMENT_BREAKDOWN_QUERY_KEY, reportParams],
    queryFn: () => reportService.getProvidersDailySettlement(reportParams),
  });

  const invalidateReportQueries = async () => {
    await queryInvalidate([config.PROVIDERS_DAILY_SETTLEMENT_QUERY_KEY]);
    await queryInvalidate([config.PROVIDERS_DAILY_SETTLEMENT_BREAKDOWN_QUERY_KEY]);
  };

  const settleTransactionsMutation = useMutation({
    mutationFn: () => reportService.settleProvidersDailySettlementTransactions(selectedTransactionIds),
    onSuccess: invalidateReportQueries,
    successNotification: lang.get('provider.dailySettlement.settleSuccess'),
  });

  const unsettleTransactionsMutation = useMutation({
    mutationFn: () => reportService.unsettleProvidersDailySettlementTransactions(selectedTransactionIds),
    onSuccess: invalidateReportQueries,
    successNotification: lang.get('provider.dailySettlement.unsettleSuccess'),
  });

  const handleSettle = async () => {
    await settleTransactionsMutation.mutateAsync();

    handleClose();
  };

  const handleUnsettle = async () => {
    await unsettleTransactionsMutation.mutateAsync();

    handleClose();
  };

  const handleViewClick = (item: ReportProvidersDailySettlementItem) => () => {
    setSelectedItem(item);

    modal.show();
  };

  const handleClose = () => {
    table.reset();

    onClose();
  };

  useTableQuery(table, reportQuery);

  const formatItemBusinessAccount = (item: ReportProvidersDailySettlementItem) => item.client.name;

  const formatItemTransactions = (item: ReportProvidersDailySettlementItem) => item.totalTransactions;

  const formatItemCurrency = (item: ReportProvidersDailySettlementItem) => item.currency;

  const formatItemTransactionAmount = (item: ReportProvidersDailySettlementItem) => formatter.formatNumber(item.totalAmount);

  const formatItemTransactionFees = (item: ReportProvidersDailySettlementItem) => formatter.formatNumber(item.grossTransactionFees);

  const formatItemNetClientPayment = (item: ReportProvidersDailySettlementItem) => formatter.formatNumber(item.netClientPayment);

  const formatItemProviderFees = (item: ReportProvidersDailySettlementItem) => formatter.formatNumber(item.totalProviderFees);

  const formatItemFeeIncome = (item: ReportProvidersDailySettlementItem) => formatter.formatNumber(item.netIncome);

  const formatItemStatus = useCallback((item: ReportProvidersDailySettlementItem) => lang.get(`provider.dailySettlement.statuses.${item.status.toLowerCase()}`), [lang]);

  const exportColumns: ExportColumns<ReportProvidersDailySettlementItem> = useMemo(() => [
    {
      title: lang.get('provider.dailySettlement.businessAccount'),
      render: (item) => formatItemBusinessAccount(item),
    }, {
      title: lang.get('provider.dailySettlement.transactions'),
      render: (item) => formatItemTransactions(item),
    }, {
      title: lang.get('provider.dailySettlement.currency'),
      render: (item) => formatItemCurrency(item),
    }, {
      title: lang.get('provider.dailySettlement.transactionAmount'),
      render: (item) => formatItemTransactionAmount(item),
    }, {
      title: lang.get('provider.dailySettlement.transactionFees'),
      render: (item) => formatItemTransactionFees(item),
    }, {
      title: lang.get('provider.dailySettlement.netClientPayment'),
      render: (item) => formatItemNetClientPayment(item),
    }, {
      title: lang.get('provider.dailySettlement.providerFees'),
      render: (item) => formatItemProviderFees(item),
    }, {
      title: lang.get('provider.dailySettlement.feeIncome'),
      render: (item) => formatItemFeeIncome(item),
    }, {
      title: lang.get('provider.dailySettlement.status'),
      render: (item) => formatItemStatus(item),
    },
  ], [lang, formatItemStatus]);

  const tableColumns: TableColumns<ReportProvidersDailySettlementItem> = [
    {
      key: 'businessAccount',
      title: lang.get('provider.dailySettlement.businessAccount'),
      render: (_, item) => (
        <Table.Truncate title={formatItemBusinessAccount(item)}>
          <BusinessAccountIcon businessAccountType={item.client.type} />
          <span>{formatItemBusinessAccount(item)}</span>
        </Table.Truncate>
      ),
    }, {
      key: 'transactions',
      title: lang.get('provider.dailySettlement.transactions'),
      render: (_, item) => formatItemTransactions(item),
    }, {
      key: 'currency',
      title: lang.get('provider.dailySettlement.currency'),
      render: (_, item) => formatItemCurrency(item),
    }, {
      className: styles.table__balance,
      key: 'transactionAmount',
      title: lang.get('provider.dailySettlement.transactionAmount'),
      render: (_, item) => formatItemTransactionAmount(item),
    }, {
      className: styles.table__balance,
      key: 'transactionFees',
      title: lang.get('provider.dailySettlement.transactionFees'),
      render: (_, item) => formatItemTransactionFees(item),
    }, {
      className: styles.table__amount,
      key: 'netClientPayment',
      title: lang.get('provider.dailySettlement.netClientPayment'),
      render: (_, item) => formatItemNetClientPayment(item),
    }, {
      className: styles.table__balance,
      key: 'providerFees',
      title: lang.get('provider.dailySettlement.providerFees'),
      render: (_, item) => formatItemProviderFees(item),
    }, {
      className: styles.table__balance,
      key: 'feeIncome',
      title: lang.get('provider.dailySettlement.feeIncome'),
      render: (_, item) => formatItemFeeIncome(item),
    }, {
      key: 'status',
      title: lang.get('provider.dailySettlement.status'),
      render: (_, item) => <SettlementStatus status={item.status} />,
    },
  ];

  const canEdit = auth.can(UserPermission.REPORTS_SETTLEMENT_MGMT);

  return (
    <Modal
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      width="large"
      open={open}
      onCancel={handleClose}
    >
      <TableView
        title={lang.get('provider.dailySettlement.breakdownTitle', {
          currency: item.currency,
          date: moment(item.date).format(config.DATE_FORMAT),
          provider: item.provider.name,
        })}
        actions={(
          <TableView.ExportButton<ReportProvidersDailySettlementItem>
            table={table}
            type="default"
            fileName={[
              EXPORT_FILE_NAME,
              item.provider.name,
              item.currency,
              moment(item.date).format(config.DATE_RAW_FORMAT),
            ].join('-')}
            columns={exportColumns}
            fetchData={() => fetchPaginatedResponseFully(reportService.getProvidersDailySettlement, reportParams)}
          />
        )}
      >

        <TableView.Actions table={table} hidden={!canEdit}>
          <PopconfirmButton
            title={lang.get('provider.dailySettlement.settleTitle')}
            color="primary"
            variant="solid"
            disabled={!table.selectedData.length}
            onConfirm={handleSettle}
          >
            {lang.get('provider.dailySettlement.settleButton')}
          </PopconfirmButton>
          <PopconfirmButton
            title={lang.get('provider.dailySettlement.unsettleTitle')}
            color="primary"
            variant="outlined"
            disabled={!table.selectedData.length}
            onConfirm={handleUnsettle}
          >
            {lang.get('provider.dailySettlement.unsettleButton')}
          </PopconfirmButton>
        </TableView.Actions>

        <TableView.Table<ReportProvidersDailySettlementItem>
          table={table}
          columns={tableColumns}
          rowKey={(item) => item.client.id + item.date + item.currency}
          loading={reportQuery.isFetching}
          clickable
          selectable={canEdit}
          onRow={(item) => ({ onClick: handleViewClick(item) })}
        />

        {selectedItem && (
          <TransactionsModal
            item={selectedItem}
            open={modal.open}
            onClose={modal.hide}
          />
        )}

      </TableView>
    </Modal>
  );
};

export default SettlementBreakdownModal;
