import React from 'react';

import { config } from 'data';
import { feeCommissionService } from 'services';
import { useLang } from 'hooks';
import { Can } from 'components/layout';
import { FeeCommissionsView } from 'components/views';
import { UserPermission } from 'types/models';

const FeeCommissionsPage = () => {
  const lang = useLang();

  return (
    <Can permissions={[UserPermission.FEE_COMMISSIONS_VIEW, UserPermission.FEE_COMMISSIONS_UPDATE]}>
      <FeeCommissionsView
        title={lang.get('feeCommission.list.title')}
        tableKey={[config.FEE_COMMISSIONS_QUERY_KEY]}
        fetchData={feeCommissionService.getFeeCommissions}
      />
    </Can>
  );
};

export default FeeCommissionsPage;
