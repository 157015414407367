import React, { FC } from 'react';

import { config } from 'data';
import { corridorTypeService } from 'services';
import { useAuth, useCountryFormatter, useCurrencyFormatter, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { Trash } from 'components/icons';
import { ActivityStatus } from 'components/layout';
import { Descriptions, Form, Modal, PopconfirmButton } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { CorridorType, UserPermission } from 'types/models';

type ViewCorridorTypeModalProps = ModalBaseProps & {
  corridorType: CorridorType;
};

const ViewCorridorTypeModal: FC<ViewCorridorTypeModalProps> = ({
  corridorType,
  open,
  onClose,
}) => {
  const auth = useAuth();
  const countryFormatter = useCountryFormatter();
  const currencyFormatter = useCurrencyFormatter();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const invalidateCorridorTypeQueries = async () => {
    await queryInvalidate([config.CORRIDOR_TYPES_QUERY_KEY]);
    await queryInvalidate([config.CORRIDOR_TYPE_QUERY_KEY, corridorType.id]);
  };

  const activateCorridorTypeMutation = useMutation({
    mutationFn: () => corridorTypeService.activateType(corridorType.id),
    onSuccess: invalidateCorridorTypeQueries,
    successNotification: lang.get('corridorType.modal.activateSuccess'),
  });

  const deactivateCorridorTypeMutation = useMutation({
    mutationFn: () => corridorTypeService.deactivateType(corridorType.id),
    onSuccess: invalidateCorridorTypeQueries,
    successNotification: lang.get('corridorType.modal.deactivateSuccess'),
  });

  const deleteCorridorTypeMutation = useMutation({
    mutationFn: () => corridorTypeService.deleteType(corridorType.id),
    onSuccess: invalidateCorridorTypeQueries,
    successNotification: lang.get('corridorType.modal.deleteSuccess'),
  });

  const handleActivate = async () => {
    await activateCorridorTypeMutation.mutateAsync();

    onClose();
  };

  const handleDeactivate = async () => {
    await deactivateCorridorTypeMutation.mutateAsync();

    onClose();
  };

  const handleDelete = async () => {
    await deleteCorridorTypeMutation.mutateAsync();

    onClose();
  };

  const canEdit = auth.can(UserPermission.CURRENCY_CORRIDOR_TRANSACTION_TYPES_UPDATE);
  const isAssigned = Boolean(corridorType?.assigned);
  const hasActions = canEdit;

  return (
    <Modal
      title={lang.get('corridorType.modal.viewTitle')}
      caption={lang.get('corridorType.modal.viewCaption')}
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      extraActions={canEdit && !isAssigned && (
        <PopconfirmButton
          title={lang.get('corridorType.modal.deleteTitle')}
          icon={<Trash />}
          danger
          loading={deleteCorridorTypeMutation.isPending}
          onConfirm={handleDelete}
        >
          {lang.get('common.actions.delete')}
        </PopconfirmButton>
      )}
      width="small"
      open={open}
      onCancel={onClose}
    >

      <Form.ActionsItem hidden={!hasActions}>
        {corridorType.active ? (
          <PopconfirmButton
            title={lang.get('corridorType.modal.deactivateTitle')}
            danger
            loading={deactivateCorridorTypeMutation.isPending}
            onConfirm={handleDeactivate}
          >
            {lang.get('common.actions.deactivate')}
          </PopconfirmButton>
        ) : (
          <PopconfirmButton
            title={lang.get('corridorType.modal.activateTitle')}
            type="primary"
            ghost
            loading={activateCorridorTypeMutation.isPending}
            onConfirm={handleActivate}
          >
            {lang.get('common.actions.activate')}
          </PopconfirmButton>
        )}
      </Form.ActionsItem>

      <Form.Divider hidden={!hasActions} />

      <Descriptions
        items={[{
          label: lang.get('corridorType.modal.level.label'),
          children: lang.get(`corridorType.levels.${corridorType.level.toLowerCase()}`),
        }, {
          label: lang.get('corridorType.modal.transactionType.label'),
          children: lang.get(`transaction.types.${corridorType.type.toLowerCase()}`),
        }, {
          label: lang.get('common.form.country.label'),
          children: countryFormatter.format(corridorType.currencyCorridor.country),
        }, {
          label: lang.get('common.form.currency.label'),
          children: currencyFormatter.format(corridorType.currencyCorridor.currency),
        }, {
          label: lang.get('common.form.status.label'),
          children: <ActivityStatus active={corridorType.active} />,
        }]}
      />

    </Modal>
  );
};

export default ViewCorridorTypeModal;
