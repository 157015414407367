import React, { FC } from 'react';

import { IconProps } from 'types/components';

const Logo: FC<IconProps> = ({
  width = '100%',
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 112 32"
    width={width}
    {...props}
  >
    <path fill="#3aafd6" d="M57.974 0h-1.786v22.028h1.785V0ZM71.22 3.87a8.704 8.704 0 0 0-6.549 2.679 8.92 8.92 0 0 0-2.605 6.549c-.148 4.986 3.87 9.153 8.856 9.228h.447c3.72 0 6.474-1.489 8.26-4.466l-1.34-.892c-1.488 2.455-3.795 3.646-6.92 3.646-1.86.074-3.721-.595-5.06-1.86C64.893 17.487 64 15.627 64 13.692H80.3l.075-1.19c0-2.308-.893-4.54-2.605-6.103-1.712-1.712-4.093-2.605-6.549-2.53Zm-7.293 8.26a7.182 7.182 0 0 1 2.381-4.763 7.646 7.646 0 0 1 9.824 0c1.414 1.191 2.232 2.903 2.307 4.763H63.927ZM86.177 0h-1.712v16.595c0 1.86.52 3.275 1.563 4.093 1.041.893 2.381 1.34 3.72 1.34h1.414v-1.712h-1.339a4.455 4.455 0 0 1-2.53-.818c-.744-.745-1.19-1.86-1.042-2.903V5.73h4.986V4.167H86.25L86.176 0ZM49.414 6.549a9.344 9.344 0 0 0-13.098 0 8.704 8.704 0 0 0-2.68 6.549c-.074 5.06 3.945 9.153 9.005 9.228h.298c2.977.074 5.73-1.34 7.442-3.796v3.498h1.712v-9.154c0-2.381-.968-4.688-2.68-6.325Zm-1.191 11.907a7.592 7.592 0 0 1-10.642 0c-1.414-1.414-2.158-3.349-2.158-5.358-.075-2.01.744-3.945 2.158-5.358a7.592 7.592 0 0 1 10.642 0c1.414 1.413 2.158 3.348 2.158 5.358 0 2.009-.744 3.944-2.158 5.358ZM111.628 12.874a8.988 8.988 0 0 0-9.005-9.004h-.149a8.704 8.704 0 0 0-6.549 2.679 8.705 8.705 0 0 0-2.679 6.549c-.074 5.06 3.944 9.153 8.93 9.228h.298c2.977.074 5.73-1.34 7.442-3.796v3.498h1.786l-.074-9.154Zm-3.87 5.582a7.592 7.592 0 0 1-10.642 0c-1.414-1.414-2.158-3.349-2.158-5.358-.075-2.01.744-3.945 2.158-5.358a7.592 7.592 0 0 1 10.642 0c1.414 1.413 2.158 3.348 2.158 5.358 0 2.009-.744 3.944-2.158 5.358Z" />
    <path fill="#000000" d="M27.014 17.34a12.64 12.64 0 0 1-12.651 12.65A12.64 12.64 0 0 1 1.71 17.34 12.64 12.64 0 0 1 14.363 4.688c2.083 0 4.167.521 6.027 1.563.447-.223.968-.52 1.414-.744.15-.074.298-.074.447-.149C15.553 1.116 6.697 2.977 2.38 9.6-1.935 16.223-.075 25.08 6.55 29.395c6.623 4.317 15.479 2.456 19.795-4.167a14.532 14.532 0 0 0 2.307-7.814c0-1.786-.372-3.572-1.042-5.21a23.734 23.734 0 0 1-1.19 1.712c.371 1.042.595 2.233.595 3.424Z" />
    <path fill="#39afd6" d="M21.731 5.581a12.109 12.109 0 0 1 2.754-.967c1.116-.298 2.232-.372 3.423-.149.744.149 1.34.67 1.712 1.414.297.744.372 1.637.223 2.456-.595 3.423-2.753 6.772-5.433 9.898-2.827 3.423-6.325 6.102-10.344 8.037a26.281 26.281 0 0 1-3.423 1.116c-1.34.372-2.753.298-4.093-.149-1.712-.595-2.753-2.232-2.68-4.018.075-1.266.447-2.456 1.117-3.498 1.042-1.786 2.233-3.423 3.721-4.837 1.265-1.265 2.53-2.456 3.944-3.498 2.084-1.563 4.242-3.051 6.475-4.316.818-.67 1.711-1.117 2.604-1.489Zm0 0c-.893.372-1.711.819-2.604 1.34a30.818 30.818 0 0 0-5.954 4.837 32.242 32.242 0 0 0-3.2 3.944A23.293 23.293 0 0 0 7.37 20.54a5.179 5.179 0 0 0-.372 2.38c.148.67.223.67.595.745.744.075 1.488 0 2.158-.149.893-.223 1.786-.595 2.68-.967a40.873 40.873 0 0 0 5.283-2.902c1.637-1.117 3.274-2.233 4.837-3.498 2.977-2.53 5.507-5.284 6.474-8.186.521-1.414.075-2.977-1.19-3.35a6.115 6.115 0 0 0-3.275 0c-.967.299-1.935.522-2.828.968Z" />
    <path fill="#3aafd6" d="M21.133 9.08a3.051 3.051 0 1 0 0-6.103 3.051 3.051 0 0 0 0 6.102Z" />
    <path fill="#000000" d="M50.381 27.014h-.819V32h6.624v-.819H50.38v-4.167ZM35.422 27.014V32h.819v-1.637h5.804v-3.35h-6.623Zm5.805 2.456h-5.06v-1.712h5.06v1.712ZM62.512 27.014V32h.818v-2.084h5.06V32h.82v-4.986h-6.698Zm5.804 2.084H63.33v-1.34h5.06l-.074 1.34ZM76.652 27.014V32h.819v-4.242h5.06V32h.745v-4.986h-6.624ZM90.863 27.014V32h6.624v-.819h-5.88v-1.265h5.88v-.818h-5.88v-1.34h5.88v-.744h-6.624ZM107.906 32h.819v-4.242h2.902v-.744h-6.623v.744h2.902V32Z" />
  </svg>
);

export default Logo;
