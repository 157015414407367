import React, { FC } from 'react';

import { config, yup } from 'data';
import { currencyService } from 'services';
import { useForm, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { CurrencySelect } from 'components/selects';
import { Form, Input, Modal } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { CurrencyRateParams } from 'types/services';

type FormValues = CurrencyRateParams;

const initialValues: Omit<FormValues, 'rate'> = {
  baseCurrency: config.DEFAULT_CURRENCY,
  targetCurrency: '',
};

const validationSchema = yup.object({
  baseCurrency: yup.string().required().currency(),
  targetCurrency: yup.string().required().currency(),
  rate: yup
    .number()
    .required()
    .decimal(config.CURRENCY_RATE_PRECISION)
    .min(config.CURRENCY_RATE_MIN)
    .max(config.CURRENCY_RATE_MAX)
    .transform((value) => value || 0),
});

const CurrencyRateModal: FC<ModalBaseProps> = ({ open, onClose }) => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const createCurrencyRateMutation = useMutation({
    mutationFn: currencyService.createCurrencyRate,
    onSuccess: () => queryInvalidate([config.CURRENCY_RATES_QUERY_KEY]),
    successNotification: lang.get('currencyRate.modal.createSuccess'),
  });

  const handleSubmit = async (values: FormValues) => {
    await createCurrencyRateMutation.mutateAsync(values);

    onClose();
  };

  return (
    <Modal
      title={lang.get('currencyRate.modal.createTitle')}
      caption={lang.get('currencyRate.modal.createCaption')}
      okText={lang.get('common.actions.create')}
      width="small"
      open={open}
      confirmLoading={createCurrencyRateMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >
        <Form.Item name="baseCurrency" label={lang.get('currencyRate.modal.baseCurrency.label')}>
          <CurrencySelect disabled />
        </Form.Item>
        <Form.Columns>
          <Form.Item name="targetCurrency" label={lang.get('common.form.currency.label')}>
            <CurrencySelect onlyActive />
          </Form.Item>
          <Form.Item name="rate" label={lang.get('currencyRate.modal.rate.label')}>
            <Input.Number placeholder={lang.get('currencyRate.modal.rate.placeholder')} />
          </Form.Item>
        </Form.Columns>
      </Form>
    </Modal>
  );
};

export default CurrencyRateModal;
