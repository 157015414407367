import React, { FC } from 'react';

import { config } from 'data';
import { corridorService } from 'services';
import { useAuth, useCountryFormatter, useCurrencyFormatter, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { Trash } from 'components/icons';
import { Descriptions, Modal, PopconfirmButton } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { Corridor, UserPermission } from 'types/models';

type ViewCorridorModalProps = ModalBaseProps & {
  corridor: Corridor;
};

const ViewCorridorModal: FC<ViewCorridorModalProps> = ({
  corridor,
  open,
  onClose,
}) => {
  const auth = useAuth();
  const countryFormatter = useCountryFormatter();
  const currencyFormatter = useCurrencyFormatter();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const invalidateCorridorQueries = async () => {
    await queryInvalidate([config.CORRIDORS_QUERY_KEY]);
    await queryInvalidate([config.CORRIDOR_QUERY_KEY, corridor.id]);
  };

  const deleteCorridorMutation = useMutation({
    mutationFn: () => corridorService.deleteCorridor(corridor.id),
    onSuccess: invalidateCorridorQueries,
    successNotification: lang.get('corridor.modal.deleteSuccess'),
  });

  const handleDelete = async () => {
    await deleteCorridorMutation.mutateAsync();

    onClose();
  };

  const canEdit = auth.can(UserPermission.CURRENCY_CORRIDORS_UPDATE);
  const isAssigned = Boolean(corridor.assigned);

  return (
    <Modal
      title={lang.get('corridor.modal.viewTitle')}
      caption={lang.get('corridor.modal.viewCaption')}
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      extraActions={canEdit && !isAssigned && (
        <PopconfirmButton
          title={lang.get('corridor.modal.deleteTitle')}
          icon={<Trash />}
          danger
          loading={deleteCorridorMutation.isPending}
          onConfirm={handleDelete}
        >
          {lang.get('common.actions.delete')}
        </PopconfirmButton>
      )}
      width="small"
      open={open}
      onCancel={onClose}
    >
      <Descriptions
        items={[{
          label: lang.get('common.form.country.label'),
          children: countryFormatter.format(corridor.country),
        }, {
          label: lang.get('common.form.currency.label'),
          children: currencyFormatter.format(corridor.currency),
        }]}
      />
    </Modal>
  );
};

export default ViewCorridorModal;
