import React from 'react';
import { Link } from 'react-router';

import { url } from 'helpers';
import { authService } from 'services';
import { useAuth, useLang, useModal, useMutation } from 'hooks';
import { ChevronDown, Logo, Logout, Setting } from 'components/icons';
import { ProfileModal } from 'components/modals';
import { Button, Container, Flex, Layout, Menu } from 'components/ui';
import { UserPermission } from 'types/models';

import styles from './styles.module.css';

const Header = () => {
  const auth = useAuth();
  const lang = useLang();
  const profileModal = useModal();

  const logoutMutation = useMutation({
    mutationFn: authService.logout,
  });

  return (
    <Layout.Header className={styles.header}>
      <Container className={styles.header__container}>

        <Link className={styles.header__logo} to={url.toDashboard()}>
          <Logo />
        </Link>

        <Menu
          rootClassName={styles.header__menu}
          items={[{
            key: 'individualsMenu',
            label: <>{lang.get('common.menu.individuals')} <ChevronDown /></>,
            disabled: auth.cannot(UserPermission.INDIVIDUALS_VIEW, UserPermission.INDIVIDUALS_UPDATE),
            children: [{
              key: 'clients',
              label: <Link to={url.toClients()}>{lang.get('common.menu.clients')}</Link>,
              disabled: auth.cannot(UserPermission.INDIVIDUALS_VIEW, UserPermission.INDIVIDUALS_UPDATE),
            }, {
              key: 'individuals',
              label: <Link to={url.toIndividuals()}>{lang.get('common.menu.individuals')}</Link>,
              disabled: auth.cannot(UserPermission.INDIVIDUALS_VIEW, UserPermission.INDIVIDUALS_UPDATE),
            }],
          }, {
            key: 'businessAccountsMenu',
            label: <>{lang.get('common.menu.businessAccounts')} <ChevronDown /></>,
            disabled: auth.cannot(
              UserPermission.BA_VIEW,
              UserPermission.BA_UPDATE,
              UserPermission.BA_APPLICATIONS_VIEW,
              UserPermission.BA_APPLICATIONS_MGMT,
            ),
            children: [{
              key: 'businessAccounts',
              label: <Link to={url.toBusinessAccounts()}>{lang.get('common.menu.businessAccounts')}</Link>,
              disabled: auth.cannot(UserPermission.BA_VIEW, UserPermission.BA_UPDATE),
            }, {
              key: 'businessApplications',
              label: <Link to={url.toBusinessApplications()}>{lang.get('common.menu.businessApplications')}</Link>,
              disabled: auth.cannot(UserPermission.BA_APPLICATIONS_VIEW, UserPermission.BA_APPLICATIONS_MGMT),
            }],
          }, {
            key: 'transactionsMenu',
            label: <>{lang.get('common.menu.transactions')} <ChevronDown /></>,
            disabled: auth.cannot(
              UserPermission.TRX_VIEW,
              UserPermission.TRX_UPDATE,
              UserPermission.TRX_BUNDLES_VIEW,
              UserPermission.TRX_BUNDLES_MGMT,
              UserPermission.TRX_BUNDLES_UPLOAD,
            ),
            children: [{
              key: 'transactions',
              label: <Link to={url.toTransactions()}>{lang.get('common.menu.transactions')}</Link>,
              disabled: auth.cannot(UserPermission.TRX_VIEW, UserPermission.TRX_UPDATE),
            }, {
              key: 'bundles',
              label: <Link to={url.toTransactionBundles()}>{lang.get('common.menu.transactionBundles')}</Link>,
              disabled: auth.cannot(
                UserPermission.TRX_BUNDLES_VIEW,
                UserPermission.TRX_BUNDLES_MGMT,
                UserPermission.TRX_BUNDLES_UPLOAD,
              ),
            }],
          }, {
            key: 'reportsMenu',
            label: <>{lang.get('common.menu.reports')} <ChevronDown /></>,
            disabled: auth.cannot(UserPermission.REPORTS_VIEW),
            children: [{
              key: 'dailyRemittance',
              label: (
                <Link to={url.toDailyRemittance()}>
                  {lang.get('common.menu.dailyRemittance')}
                </Link>
              ),
              disabled: auth.cannot(UserPermission.REPORTS_VIEW),
            }, {
              key: 'businessAccountsDailyBalance',
              label: (
                <Link to={url.toBusinessAccountsDailyBalance()}>
                  {lang.get('common.menu.businessAccountsDailyBalance')}
                </Link>
              ),
              disabled: auth.cannot(UserPermission.REPORTS_VIEW),
            }, {
              key: 'businessAccountsDailyRevenue',
              label: (
                <Link to={url.toBusinessAccountsDailyRevenue()}>
                  {lang.get('common.menu.businessAccountsDailyRevenue')}
                </Link>
              ),
              disabled: auth.cannot(UserPermission.REPORTS_VIEW),
            }, {
              key: 'providersDailyBalance',
              label: (
                <Link to={url.toProvidersDailyBalance()}>
                  {lang.get('common.menu.providersDailyBalance')}
                </Link>
              ),
              disabled: auth.cannot(UserPermission.REPORTS_VIEW),
            }, {
              key: 'providersDailySettlement',
              label: (
                <Link to={url.toProvidersDailySettlement()}>
                  {lang.get('common.menu.providersDailySettlement')}
                </Link>
              ),
              disabled: auth.cannot(UserPermission.REPORTS_VIEW),
            }],
          }, {
            key: 'settingsMenu',
            label: <>{lang.get('common.menu.settings')} <ChevronDown /></>,
            disabled: auth.cannot(
              UserPermission.AUDIT_LOGS_VIEW,
              UserPermission.COSTS_VIEW,
              UserPermission.COSTS_UPDATE,
              UserPermission.PROVIDERS_VIEW,
              UserPermission.PROVIDERS_UPDATE,
              UserPermission.CURRENCY_CORRIDORS_VIEW,
              UserPermission.CURRENCY_CORRIDORS_UPDATE,
              UserPermission.CURRENCY_RATES_VIEW,
              UserPermission.CURRENCY_RATES_UPDATE,
              UserPermission.FEE_COMMISSIONS_VIEW,
              UserPermission.FEE_COMMISSIONS_UPDATE,
              UserPermission.FEE_GROUPS_VIEW,
              UserPermission.FEE_GROUPS_UPDATE,
              UserPermission.USERS_VIEW,
              UserPermission.USERS_UPDATE,
              UserPermission.USER_ROLES_VIEW,
              UserPermission.USER_ROLES_UPDATE,
            ),
            children: [{
              key: 'auditLogs',
              label: <Link to={url.toAuditLogs()}>{lang.get('common.menu.auditLogs')}</Link>,
              disabled: auth.cannot(UserPermission.AUDIT_LOGS_VIEW),
            }, {
              key: 'corridors',
              label: <Link to={url.toCorridors()}>{lang.get('common.menu.corridors')}</Link>,
              disabled: auth.cannot(UserPermission.CURRENCY_CORRIDORS_VIEW, UserPermission.CURRENCY_CORRIDORS_UPDATE),
            }, {
              key: 'costManagement',
              label: <Link to={url.toCostManagement()}>{lang.get('common.menu.costManagement')}</Link>,
              disabled: auth.cannot(
                UserPermission.COSTS_VIEW,
                UserPermission.COSTS_UPDATE,
                UserPermission.PROVIDERS_VIEW,
                UserPermission.PROVIDERS_UPDATE,
              ),
            }, {
              key: 'currencies',
              label: <Link to={url.toCurrencies()}>{lang.get('common.menu.currencies')}</Link>,
              disabled: auth.cannot(UserPermission.CURRENCY_RATES_VIEW, UserPermission.CURRENCY_RATES_UPDATE),
            }, {
              key: 'feeManagement',
              label: <Link to={url.toFeeManagement()}>{lang.get('common.menu.feeManagement')}</Link>,
              disabled: auth.cannot(
                UserPermission.FEE_COMMISSIONS_VIEW,
                UserPermission.FEE_COMMISSIONS_UPDATE,
                UserPermission.FEE_GROUPS_VIEW,
                UserPermission.FEE_GROUPS_UPDATE,
              ),
            }, {
              key: 'users',
              label: <Link to={url.toUsers()}>{lang.get('common.menu.users')}</Link>,
              disabled: auth.cannot(
                UserPermission.USERS_VIEW,
                UserPermission.USERS_UPDATE,
                UserPermission.USER_ROLES_VIEW,
                UserPermission.USER_ROLES_UPDATE,
              ),
            }],
          }]}
          mode="horizontal"
        />

        <Flex className={styles.header__aside} gap="small">
          <Button
            className={styles.header__aside__button}
            title={lang.get('common.menu.profile')}
            icon={<Setting />}
            color="primary"
            variant="filled"
            onClick={profileModal.show}
          />
          <Button
            className={styles.header__aside__button}
            title={lang.get('common.menu.logout')}
            icon={<Logout />}
            color="primary"
            variant="outlined"
            loading={logoutMutation.isPending}
            onClick={() => logoutMutation.mutateAsync()}
          />
        </Flex>

        <ProfileModal
          open={profileModal.open}
          onClose={profileModal.hide}
        />

      </Container>
    </Layout.Header>
  );
};

export default Header;
