import React, { FC } from 'react';
import { useParams } from 'react-router';

import { url } from 'helpers';
import { useBusinessAccountQuery } from 'hooks/queries';
import { BusinessAccountExtended } from 'types/models';

import SinglePage from '.';

type BusinessAccountPageProps = {
  render: (businessAccount: BusinessAccountExtended) => ReturnType<FC>;
};

const BusinessAccountPage: FC<BusinessAccountPageProps> = ({ render }) => {
  const params = useParams();

  return (
    <SinglePage
      query={useBusinessAccountQuery(params.id)}
      returnUrl={url.toBusinessAccounts()}
      render={render}
    />
  );
};

export default BusinessAccountPage;
