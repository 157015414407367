import React, { FC } from 'react';

import { formatter } from 'helpers';
import { useLang } from 'hooks';
import { Descriptions, Link, Tag } from 'components/ui';
import { Nullable, Override } from 'types/common';
import { DescriptionsProps } from 'types/components';
import { Verification } from 'types/models';

import UserInfo from '../UserInfo';

type VerificationDescriptionsProps = Override<Omit<DescriptionsProps, 'items'>, {
  verification: Nullable<Verification>;
}>;

const VerificationDescriptions: FC<VerificationDescriptionsProps> = ({ verification, ...props }) => {
  const lang = useLang();

  return (
    <Descriptions
      items={[{
        label: lang.get('common.form.verificationUser.label'),
        children: verification?.updatedBy
          ? (
            <UserInfo
              name={formatter.formatName(verification.updatedBy.firstName, verification.updatedBy.lastName)}
              email={verification.updatedBy.email}
            />
          )
          : '-',
      }, {
        label: lang.get('common.form.verificationDetails.label'),
        children: verification?.detailsLink
          ? <Link href={verification.detailsLink}>{lang.get('common.actions.view')}</Link>
          : '-',
      }, {
        label: lang.get('common.form.verificationDate.label'),
        children: verification?.updatedAt
          ? formatter.formatDateTime(verification.updatedAt)
          : '-',
      }, {
        label: lang.get('common.form.verificationStatus.label'),
        children: (
          <Tag color={verification?.verified ? 'success' : 'error'}>
            {lang.get(verification?.verified ? 'common.verificationStatuses.verified' : 'common.verificationStatuses.unverified')}
          </Tag>
        ),
      }]}
      {...props}
    />
  );
};

export default VerificationDescriptions;
