import React, { FC } from 'react';

import { config, yup } from 'data';
import { corridorService } from 'services';
import { useForm, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { CountrySelect, CurrencySelect } from 'components/selects';
import { Form, Modal } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { CorridorParams } from 'types/services';

type FormValues = CorridorParams;

const initialValues: FormValues = {
  country: '',
  currency: '',
};

const validationSchema = yup.object({
  country: yup.string().required().country(),
  currency: yup.string().required().currency(),
});

const CreateCorridorModal: FC<ModalBaseProps> = ({ open, onClose }) => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const invalidateCorridorQueries = async () => {
    await queryInvalidate([config.CORRIDORS_QUERY_KEY]);
  };

  const createCorridorMutation = useMutation({
    mutationFn: corridorService.createCorridor,
    onSuccess: invalidateCorridorQueries,
    successNotification: lang.get('corridor.modal.createSuccess'),
  });

  const handleSubmit = async (values: FormValues) => {
    await createCorridorMutation.mutateAsync(values);

    onClose();
  };

  return (
    <Modal
      title={lang.get('corridor.modal.createTitle')}
      caption={lang.get('corridor.modal.createCaption')}
      okText={lang.get('common.actions.create')}
      width="small"
      open={open}
      confirmLoading={createCorridorMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >
        <Form.Item name="country" label={lang.get('common.form.country.label')}>
          <CountrySelect includeAny />
        </Form.Item>
        <Form.Item name="currency" label={lang.get('common.form.currency.label')}>
          <CurrencySelect includeAny />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateCorridorModal;
