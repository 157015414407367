import React, { FC, useEffect } from 'react';
import defaults from 'lodash/defaults';

import { config, moment, yup } from 'data';
import { useForm, useLang } from 'hooks';
import { useClientVerificationDetailsQuery } from 'hooks/queries';
import { CountrySelect } from 'components/selects';
import { DatePicker, Form, Input, Modal, Select } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { Client, ClientGender, ClientIdType } from 'types/models';
import { ClientVerifyParams } from 'types/services';

type FormValues = ClientVerifyParams;

const genders = Object.values(ClientGender);
const idTypes = Object.values(ClientIdType);

const initialValues: Omit<FormValues, 'idType' | 'gender'> = {
  customerId: '',
  dateOfBirth: '',
  issuanceCountryCode: '',
  nationalityCountryCode: '',
  address: {
    countryCode: '',
    state: '',
    city: '',
    street: '',
    postalCode: '',
  },
};

const validationSchema = yup.object({
  idType: yup.string().required().oneOf(idTypes),
  customerId: yup.string().required().max(config.STRING_MAX_LENGTH),
  gender: yup.string().required().oneOf(genders),
  dateOfBirth: yup.string().required(),
  issuanceCountryCode: yup.string().required().country(),
  nationalityCountryCode: yup.string().required().country(),
  address: yup.object({
    countryCode: yup.string().required().country(),
    state: yup.string().required().min(config.STATE_MIN_LENGTH).max(config.STATE_MAX_LENGTH),
    city: yup.string().required().min(config.CITY_MIN_LENGTH).max(config.CITY_MAX_LENGTH),
    street: yup.string().required().min(config.STREET_MIN_LENGTH).max(config.STREET_MAX_LENGTH),
    postalCode: yup.string().required().min(config.ZIP_MIN_LENGTH).max(config.ZIP_MAX_LENGTH),
  }),
});

type VerifyClientModalProps = ModalBaseProps & {
  client: Client;
  loading?: boolean;
  onSubmit: (values: FormValues) => Promise<void>;
};

const VerifyClientModal: FC<VerifyClientModalProps> = ({
  client,
  open,
  loading,
  onClose,
  onSubmit,
}) => {
  const form = useForm<FormValues>();
  const lang = useLang();

  const detailsQuery = useClientVerificationDetailsQuery(client.id);

  const handleSubmit = async (values: FormValues) => {
    await onSubmit(values);

    onClose();
  };

  useEffect(() => {
    if (open && detailsQuery.data) {
      form.setFieldsValue(defaults(detailsQuery.data, initialValues) as FormValues);
    }
  }, [open, form, detailsQuery.data]);

  return (
    <Modal
      title={lang.get('client.verifyModal.title', { email: client.email })}
      caption={lang.get('client.verifyModal.caption')}
      okText={lang.get('common.actions.verify')}
      width="small"
      open={open}
      confirmLoading={loading}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        disabled={detailsQuery.isFetching}
        onFinish={handleSubmit}
      >

        <Form.Title>{lang.get('client.verifyModal.id')}</Form.Title>
        <Form.Columns>
          <Form.Item name="idType" label={lang.get('client.verifyModal.idType.label')}>
            <Select
              placeholder={lang.get('client.verifyModal.idType.placeholder')}
              options={idTypes.map((type) => ({
                value: type,
                label: lang.get(`client.idTypes.${type.toLowerCase()}`),
              }))}
            />
          </Form.Item>
          <Form.Item name="customerId" label={lang.get('client.verifyModal.idNumber.label')}>
            <Input placeholder={lang.get('client.verifyModal.idNumber.placeholder')} />
          </Form.Item>
        </Form.Columns>
        <Form.Item name="issuanceCountryCode" label={lang.get('client.verifyModal.idCountry.label')}>
          <CountrySelect />
        </Form.Item>

        <Form.Divider />

        <Form.Title>{lang.get('client.verifyModal.info')}</Form.Title>
        <Form.Columns>
          <Form.Item name="gender" label={lang.get('client.verifyModal.gender.label')}>
            <Select
              placeholder={lang.get('client.verifyModal.gender.placeholder')}
              options={genders.map((gender) => ({
                value: gender,
                label: lang.get(`client.genders.${gender.toLowerCase()}`),
              }))}
            />
          </Form.Item>
          <Form.Item name="dateOfBirth" label={lang.get('common.form.birthday.label')}>
            <DatePicker maxDate={moment().subtract(1, 'day').endOf('day')} allowClear={false} />
          </Form.Item>
        </Form.Columns>
        <Form.Item name="nationalityCountryCode" label={lang.get('client.verifyModal.country.label')}>
          <CountrySelect />
        </Form.Item>

        <Form.Divider />

        <Form.Title>{lang.get('client.verifyModal.address')}</Form.Title>
        <Form.Columns>
          <Form.Item name={['address', 'countryCode']} label={lang.get('common.form.country.label')}>
            <CountrySelect />
          </Form.Item>
          <Form.Item name={['address', 'state']} label={lang.get('common.form.state.label')}>
            <Input placeholder={lang.get('common.form.state.placeholder')} />
          </Form.Item>
        </Form.Columns>
        <Form.Columns>
          <Form.Item name={['address', 'city']} label={lang.get('common.form.city.label')}>
            <Input placeholder={lang.get('common.form.city.placeholder')} />
          </Form.Item>
          <Form.Item name={['address', 'postalCode']} label={lang.get('common.form.zip.label')}>
            <Input placeholder={lang.get('common.form.zip.placeholder')} />
          </Form.Item>
        </Form.Columns>
        <Form.Item name={['address', 'street']} label={lang.get('common.form.street.label')}>
          <Input placeholder={lang.get('common.form.street.placeholder')} />
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default VerifyClientModal;
