import React, { FC } from 'react';

import { config, yup } from 'data';
import { transactionBundleService } from 'services';
import { useForm, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { VirtualAccountSelect } from 'components/selects';
import { Form, Modal } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { TransactionBundle } from 'types/models';
import { BundleApproveParams } from 'types/services';

type FormValues = BundleApproveParams;

const initialValues: FormValues = {
  virtualAccountId: '',
};

const validationSchema = yup.object({
  virtualAccountId: yup.string().required().uuid(),
});

type ApproveBundleModalProps = ModalBaseProps & {
  bundle: TransactionBundle;
  onSubmit: VoidFunction;
};

const ApproveBundleModal: FC<ApproveBundleModalProps> = ({
  bundle,
  open,
  onClose,
  onSubmit,
}) => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const invalidateBundleQueries = async () => {
    await queryInvalidate([config.TRANSACTION_BUNDLES_QUERY_KEY]);
    await queryInvalidate([config.TRANSACTION_BUNDLE_QUERY_KEY, bundle.id]);
  };

  const approveBundleMutation = useMutation({
    mutationFn: (values: FormValues) => transactionBundleService.approveBundle(bundle.id, values),
    onSuccess: invalidateBundleQueries,
    successNotification: lang.get('transactionBundle.modal.approveSuccess'),
  });

  const handleSubmit = async (values: FormValues) => {
    await approveBundleMutation.mutateAsync(values);

    onSubmit();
    onClose();
  };

  return (
    <Modal
      title={lang.get('transactionBundle.modal.businessAccountTitle', { name: bundle.client.name })}
      caption={lang.get('transactionBundle.modal.approveCaption')}
      okText={lang.get('common.actions.approve')}
      width="small"
      open={open}
      confirmLoading={approveBundleMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >
        <Form.Item name="virtualAccountId" label={lang.get('transactionBundle.modal.account.label')}>
          <VirtualAccountSelect
            businessAccountId={bundle.client.id}
            placeholder={lang.get('transactionBundle.modal.account.placeholder')}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ApproveBundleModal;
