import React, { FC, useMemo, useState } from 'react';
import truncate from 'lodash/truncate';

import { config, moment } from 'data';
import { formatter } from 'helpers';
import { transactionService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useAuth, useForm, useFormWatch, useLang, useQueryModal, useTable, useTableQuery } from 'hooks';
import { useTransactionQuery, useTransactionsQuery } from 'hooks/queries';
import { TopUp } from 'components/icons';
import { BusinessAccountIcon, TableView, TransactionStatus } from 'components/layout';
import { TransactionModal } from 'components/modals';
import { CountrySelect, CurrencySelect, ProviderSelect } from 'components/selects';
import { Button, Checkbox, DateRangePicker, Flex, Form, Select, Space, Table } from 'components/ui';
import { Uuid } from 'types/common';
import { ExportColumns, TableColumns } from 'types/components';
import { TransactionsParams } from 'types/services';
import { TransactionsViewFilters } from 'types/views';

import {
  BusinessAccount,
  BusinessAccountType,
  Transaction,
  TransactionDirection as ETransactionDirection,
  TransactionPayoutMethod,
  TransactionStatus as ETransactionStatus,
  TransactionType as ETransactionType,
  UserPermission,
} from 'types/models';

import styles from './styles.module.css';

const EXPORT_FILE_NAME = 'transactions';

type TableParams = TransactionsViewFilters;

const initialTableParams: TableParams = {
  //
};

const typeDirections = transactionService.getTransactionTypeDirections();

type TransactionsViewProps = {
  businessAccount?: BusinessAccount;
};

const TransactionsView: FC<TransactionsViewProps> = ({ businessAccount }) => {
  const auth = useAuth();
  const filtersForm = useForm<TableParams>();
  const lang = useLang();
  const table = useTable<Transaction, TableParams>([config.TRANSACTIONS_QUERY_KEY, businessAccount?.id], initialTableParams);

  const currentDirectionFilter = useFormWatch('direction', filtersForm);

  const [transactionId, setTransactionId] = useState<Uuid>();

  const modal = useQueryModal(config.TRANSACTIONS_QUERY_KEY, setTransactionId);

  const transactionsParams: TransactionsParams = {
    clientId: businessAccount?.id,
    page: table.page,
    search: table.params.search || undefined,
    providerId: table.params.providerId || undefined,
    country: table.params.country || undefined,
    destinationCurrency: table.params.destinationCurrency || undefined,
    direction: table.params.direction || undefined,
    originCurrency: table.params.originCurrency || undefined,
    payoutMethod: table.params.payoutMethod || undefined,
    status: table.params.status || undefined,
    transactionType: table.params.transactionType || undefined,
    dateFrom: (table.params.dates && table.params.dates[0]) || undefined,
    dateTo: (table.params.dates && table.params.dates[1]) || undefined,
  };

  const transactionsQuery = useTransactionsQuery(transactionsParams);
  const transactionQuery = useTransactionQuery(transactionId);

  const handleCreateClick = () => {
    modal.show();
  };

  const handleViewClick = (transaction: Transaction) => () => {
    modal.show(transaction.id);
  };

  useTableQuery(table, transactionsQuery);

  const getTransactionUpdateDate = (transaction: Transaction) => transaction.statusChangedAt ?? transaction.createdAt;

  const formatTransactionBusinessAccount = (transaction: Transaction) => transaction.client.name;

  const formatTransactionProvider = (transaction: Transaction) => transaction.provider?.name ?? '-';

  const exportColumns: ExportColumns<Transaction> = useMemo(() => [
    {
      title: lang.get('transaction.list.creationDate'),
      render: (transaction) => formatter.formatDate(transaction.createdAt),
    }, {
      title: lang.get('transaction.list.creationTime'),
      render: (transaction) => formatter.formatTime(transaction.createdAt),
    }, {
      title: lang.get('transaction.list.updateDate'),
      render: (transaction) => formatter.formatDate(getTransactionUpdateDate(transaction)),
    }, {
      title: lang.get('transaction.list.updateTime'),
      render: (transaction) => formatter.formatTime(getTransactionUpdateDate(transaction)),
    }, {
      title: lang.get('transaction.list.id'),
      render: (transaction) => transaction.id,
    }, {
      title: lang.get('transaction.list.flowId'),
      render: (transaction) => transaction.flowId ?? '-',
    }, {
      title: lang.get('transaction.list.clientReferenceId'),
      render: (transaction) => transaction.clientReferenceId ?? '-',
    }, {
      title: lang.get('transaction.list.accountId'),
      render: (transaction) => transaction.virtualAccount?.id ?? '-',
    }, {
      title: lang.get('transaction.list.accountNumber'),
      render: (transaction) => transaction.virtualAccount?.externalId ?? '-',
    }, {
      title: lang.get('transaction.list.businessAccount'),
      render: (transaction) => formatTransactionBusinessAccount(transaction),
    }, {
      title: lang.get('transaction.list.beneficiary'),
      render: (transaction) => transaction.beneficiary ?? '-',
    }, {
      title: lang.get('transaction.list.provider'),
      render: (transaction) => formatTransactionProvider(transaction),
    }, {
      title: lang.get('transaction.list.type'),
      render: (transaction) => lang.get(`transaction.types.${transaction.transactionType.toLowerCase()}`),
    }, {
      title: lang.get('transaction.list.incoming'),
      render: (transaction) => formatter.formatNumber(transaction.previousBalance),
    }, {
      title: lang.get('transaction.list.originCurrency'),
      render: (transaction) => transaction.originCurrency,
    }, {
      title: lang.get('transaction.list.originAmount'),
      render: (transaction) => formatter.formatNumber(transaction.originAmount),
    }, {
      title: lang.get('transaction.list.destinationCurrency'),
      render: (transaction) => transaction.destinationCurrency,
    }, {
      title: lang.get('transaction.list.destinationAmount'),
      render: (transaction) => formatter.formatNumber(transaction.destinationAmount),
    }, {
      title: lang.get('transaction.list.fees'),
      render: (transaction) => formatter.formatNumber(transaction.transactionFee),
    }, {
      title: lang.get('transaction.list.outgoing'),
      render: (transaction) => formatter.formatNumber(transaction.balance),
    }, {
      title: lang.get('transaction.list.status'),
      render: (transaction) => lang.get(`transaction.statuses.${transaction.status.toLowerCase()}`),
    },
  ], [lang]);

  const tableColumns: TableColumns<Transaction> = [
    {
      className: styles.table__date,
      key: 'date',
      title: lang.get('transaction.list.date'),
      render: (_, transaction) => (
        <span title={formatter.formatDateTime(getTransactionUpdateDate(transaction))}>
          {formatter.formatDate(getTransactionUpdateDate(transaction))}
        </span>
      ),
    }, {
      className: styles.table__id,
      key: 'id',
      title: lang.get('transaction.list.id'),
      render: (_, transaction) => (
        <span title={transaction.id}>
          {truncate(transaction.id, { length: config.TABLE_ID_MAX_LENGTH })}
        </span>
      ),
    }, {
      key: 'businessAccount',
      title: lang.get('transaction.list.businessAccount'),
      hidden: Boolean(businessAccount),
      render: (_, transaction) => (
        <Table.Truncate title={formatTransactionBusinessAccount(transaction)}>
          <BusinessAccountIcon businessAccountType={transaction.client.type} />
          <span>{formatTransactionBusinessAccount(transaction)}</span>
        </Table.Truncate>
      ),
    }, {
      key: 'provider',
      title: lang.get('transaction.list.provider'),
      render: (_, transaction) => formatTransactionProvider(transaction),
    }, {
      className: styles.table__balance,
      key: 'incoming',
      title: lang.get('transaction.list.incoming'),
      render: (_, transaction) => formatter.formatCurrency(transaction.previousBalance, transaction.originCurrency),
    }, {
      className: styles.table__amount,
      key: 'originAmount',
      title: lang.get('transaction.list.originAmount'),
      render: (_, transaction) => formatter.formatCurrency(transaction.originAmount, transaction.originCurrency),
    }, {
      className: styles.table__amount,
      key: 'destinationAmount',
      title: lang.get('transaction.list.destinationAmount'),
      render: (_, transaction) => formatter.formatCurrency(transaction.destinationAmount, transaction.destinationCurrency),
    }, {
      className: styles.table__balance,
      key: 'fees',
      title: lang.get('transaction.list.fees'),
      render: (_, transaction) => formatter.formatCurrency(transaction.transactionFee, transaction.originCurrency),
    }, {
      className: styles.table__balance,
      key: 'outgoing',
      title: lang.get('transaction.list.outgoing'),
      render: (_, transaction) => formatter.formatCurrency(transaction.balance, transaction.originCurrency),
    }, {
      key: 'status',
      title: lang.get('transaction.list.status'),
      render: (_, transaction) => <TransactionStatus status={transaction.status} />,
    },
  ];

  return (
    <TableView
      title={lang.get('transaction.list.title')}
      actions={(
        <Flex gap="small" wrap="wrap">
          <TableView.ExportButton<Transaction>
            table={table}
            type="default"
            fileName={[
              EXPORT_FILE_NAME,
              businessAccount?.name,
              table.params.dates && table.params.dates[0] && moment(table.params.dates[0]).format(config.DATE_RAW_FORMAT),
              table.params.dates && table.params.dates[1] && moment(table.params.dates[1]).format(config.DATE_RAW_FORMAT),
            ].filter(Boolean).join('-')}
            columns={exportColumns}
            fetchData={() => fetchPaginatedResponseFully(transactionService.getTransactions, transactionsParams)}
          />
          <Button
            icon={<TopUp />}
            hidden={(() => {
              if (auth.can(UserPermission.TRX_UPDATE)) {
                return false;
              }

              if (businessAccount) {
                return auth.cannot(
                  businessAccount.type === BusinessAccountType.INDIVIDUAL
                    ? UserPermission.INDIVIDUALS_TRX_UPDATE
                    : UserPermission.BA_TRX_UPDATE,
                );
              }

              return auth.cannot(UserPermission.BA_TRX_UPDATE, UserPermission.INDIVIDUALS_TRX_UPDATE);
            })()}
            onClick={handleCreateClick}
          >
            {lang.get('transaction.list.create')}
          </Button>
        </Flex>
      )}
    >

      <TableView.Filters<TableParams>
        form={filtersForm}
        initialValues={initialTableParams}
        values={table.params}
        withSearch
        onSubmit={table.setParams}
      >
        <Form.Item name="providerId" label={lang.get('common.filters.provider')}>
          <ProviderSelect placeholder={lang.get('common.actions.all')} allowClear />
        </Form.Item>
        <Form.Item name="country" label={lang.get('common.filters.country')}>
          <CountrySelect placeholder={lang.get('common.actions.all')} allowClear />
        </Form.Item>
        <Form.Item name="originCurrency" label={lang.get('transaction.filters.originCurrency')}>
          <CurrencySelect placeholder={lang.get('common.actions.all')} allowClear />
        </Form.Item>
        <Form.Item name="destinationCurrency" label={lang.get('transaction.filters.destinationCurrency')}>
          <CurrencySelect placeholder={lang.get('common.actions.all')} allowClear />
        </Form.Item>
        <Form.Item name="payoutMethod" label={lang.get('transaction.filters.payoutMethod')}>
          <Select
            placeholder={lang.get('common.actions.all')}
            options={Object.values(TransactionPayoutMethod).map((method) => ({
              value: method,
              label: lang.get(`transaction.payoutMethods.${method.toLowerCase()}`),
            }))}
            allowClear
          />
        </Form.Item>
        <Form.Item name="direction" label={lang.get('transaction.filters.direction')}>
          <Select
            placeholder={lang.get('common.actions.all')}
            options={Object.values(ETransactionDirection).map((direction) => ({
              value: direction,
              label: lang.get(`transaction.directions.${direction.toLowerCase()}`),
            }))}
            allowClear
          />
        </Form.Item>
        <Form.Divider />
        <Form.Item name="transactionType" label={lang.get('common.filters.type')}>
          <Checkbox.Group>
            <Space direction="vertical">
              {Object.values(ETransactionType).map((type) => (
                <Checkbox
                  key={type}
                  value={type}
                  disabled={currentDirectionFilter && !typeDirections[type].includes(currentDirectionFilter)}
                >
                  {lang.get(`transaction.types.${type.toLowerCase()}`)}
                </Checkbox>
              ))}
            </Space>
          </Checkbox.Group>
        </Form.Item>
        <Form.Divider />
        <Form.Item name="status" label={lang.get('common.filters.status')}>
          <Checkbox.Group>
            <Space direction="vertical">
              {Object.values(ETransactionStatus).map((status) => (
                <Checkbox key={status} value={status}>
                  {lang.get(`transaction.statuses.${status.toLowerCase()}`)}
                </Checkbox>
              ))}
            </Space>
          </Checkbox.Group>
        </Form.Item>
        <Form.Divider />
        <Form.Item name="dates" label={lang.get('common.filters.date')}>
          <DateRangePicker maxDate={moment()} allowEmpty />
        </Form.Item>
      </TableView.Filters>

      <TableView.Table<Transaction>
        table={table}
        columns={tableColumns}
        rowKey={(transaction) => transaction.id}
        loading={transactionsQuery.isFetching}
        clickable
        onRow={(transaction) => ({ onClick: handleViewClick(transaction) })}
      />

      <TransactionModal
        businessAccount={businessAccount}
        transaction={transactionQuery.data || undefined}
        open={!transactionQuery.isFetching && modal.open}
        onClose={modal.hide}
      />

    </TableView>
  );
};

export default TransactionsView;
