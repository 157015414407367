import React, { FC, useEffect } from 'react';

import { config, yup } from 'data';
import { businessApplicationService } from 'services';
import { useAuth, useForm, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { useAllBusinessApplicationIndustriesQuery } from 'hooks/queries';
import { Form, Input, Modal, Select, TextArea } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { BusinessApplication, UserPermission } from 'types/models';
import { BusinessApplicationParams } from 'types/services';

import Actions from './Actions';

type FormValues = BusinessApplicationParams;

const initialValues: FormValues = {
  industryId: '',
  name: '',
  email: '',
  activity: '',
};

const validationSchema = yup.object({
  industryId: yup.string().required().uuid(),
  name: yup
    .string()
    .required()
    .trim()
    .min(config.BUSINESS_APPLICATION_NAME_MIN_LENGTH)
    .max(config.BUSINESS_APPLICATION_NAME_MAX_LENGTH),
  email: yup.string().required().email(),
  activity: yup
    .string()
    .required()
    .trim()
    .min(config.BUSINESS_APPLICATION_ACTIVITY_MIN_LENGTH)
    .max(config.BUSINESS_APPLICATION_ACTIVITY_MAX_LENGTH),
});

type EditBusinessApplicationModalProps = ModalBaseProps & {
  businessApplication?: BusinessApplication;
};

const EditBusinessApplicationModal: FC<EditBusinessApplicationModalProps> = ({
  businessApplication,
  open,
  onClose,
}) => {
  const auth = useAuth();
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const businessApplicationId = businessApplication?.id ?? '';
  const businessApplicationName = businessApplication?.name ?? '';

  const industriesQuery = useAllBusinessApplicationIndustriesQuery();

  const invalidateBusinessApplicationQueries = async () => {
    await queryInvalidate([config.BUSINESS_APPLICATIONS_QUERY_KEY]);
    await queryInvalidate([config.BUSINESS_APPLICATION_QUERY_KEY, businessApplicationId]);
  };

  const createBusinessApplicationMutation = useMutation({
    mutationFn: businessApplicationService.createBusinessApplication,
    onSuccess: invalidateBusinessApplicationQueries,
    successNotification: lang.get('businessApplication.modal.createSuccess'),
  });

  const updateBusinessApplicationMutation = useMutation({
    mutationFn: (values: FormValues) => businessApplicationService.updateBusinessApplication(businessApplicationId, values),
    onSuccess: invalidateBusinessApplicationQueries,
    successNotification: lang.get('businessApplication.modal.updateSuccess', { name: businessApplicationName }),
  });

  const handleSubmit = async (values: FormValues) => {
    businessApplication
      ? await updateBusinessApplicationMutation.mutateAsync(values)
      : await createBusinessApplicationMutation.mutateAsync(values);

    onClose();
  };

  useEffect(() => {
    if (open && businessApplication) {
      form.setFieldsValue({
        ...businessApplication,
        industryId: businessApplication.mainIndustry?.id,
      });
    }
  }, [businessApplication, open, form, industriesQuery.data]);

  const canEdit = auth.can(UserPermission.BA_APPLICATIONS_MGMT);

  return (
    <Modal
      title={
        businessApplication
          ? lang.get('businessApplication.modal.updateTitle', { name: businessApplication.name })
          : lang.get('businessApplication.modal.createTitle')
      }
      caption={
        businessApplication
          ? lang.get('businessApplication.modal.updateCaption')
          : lang.get('businessApplication.modal.createCaption')
      }
      okText={businessApplication ? lang.get('common.actions.save') : lang.get('common.actions.create')}
      cancelText={!canEdit ? lang.get('common.actions.close') : null}
      okButtonProps={{ hidden: !canEdit }}
      open={open}
      confirmLoading={createBusinessApplicationMutation.isPending || updateBusinessApplicationMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        disabled={!canEdit}
        onFinish={handleSubmit}
      >

        {businessApplication && <Actions businessApplication={businessApplication} onSubmit={onClose} />}

        <Form.Columns>
          <Form.Item name="name" label={lang.get('common.form.name.label')}>
            <Input placeholder={lang.get('common.form.name.placeholder')} />
          </Form.Item>
          <Form.Item name="email" label={lang.get('common.form.email.label')}>
            <Input.Email />
          </Form.Item>
        </Form.Columns>
        <Form.Item name="industryId" label={lang.get('businessApplication.modal.industry.label')}>
          <Select
            placeholder={lang.get('businessApplication.modal.industry.placeholder')}
            options={industriesQuery.data?.map((industry) => ({
              value: industry.id,
              label: industry.value,
            }))}
            loading={industriesQuery.isFetching}
          />
        </Form.Item>
        <Form.Item name="activity" label={lang.get('businessApplication.modal.activity.label')}>
          <TextArea placeholder={lang.get('businessApplication.modal.activity.placeholder')} />
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default EditBusinessApplicationModal;
