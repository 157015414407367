import React from 'react';

import { useLang } from 'hooks';
import { DashboardLayout } from 'layouts';
import { Grid } from 'components/ui';

import List from './List';
import Rates from './Rates';

const CurrenciesPage = () => {
  const lang = useLang();

  return (
    <DashboardLayout title={lang.get('currency.title')}>
      <Grid.Row gutter={[8, 8]}>
        <Grid.Col span={24} md={12}>
          <List />
        </Grid.Col>
        <Grid.Col span={24}>
          <Rates />
        </Grid.Col>
      </Grid.Row>
    </DashboardLayout>
  );
};

export default CurrenciesPage;
