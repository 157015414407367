import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig, isAxiosError } from 'axios';

import { file, lang } from 'helpers';
import { authService } from 'services';
import { ContentType, HttpStatus } from 'types/common';
import { AuthEndpoint } from 'types/services';

import config from './config';

const PUBLIC_ENDPOINTS: string[] = [
  AuthEndpoint.CHANGE_PASSWORD,
  AuthEndpoint.LOGIN,
  AuthEndpoint.LOGOUT,
  AuthEndpoint.REFRESH_ACCESS_TOKEN,
  AuthEndpoint.RESET_PASSWORD,
  AuthEndpoint.RESET_PASSWORD_CONFIRM,
];

let refreshAccessTokenRetrying = false;
let refreshAccessTokenPromise: Promise<AxiosResponse> | null = null;

const transformRequestFiles = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  if (!config.data || typeof config.data !== 'object') {
    return config;
  }

  for (const key in config.data) {
    let value = config.data[key];
    let files = Array.isArray(value) ? value : [value];

    if (files.every(file.isUploadFileLike)) {
      files = files.filter(file.isUploadFile).map((file) => file.originFileObj);
      value = Array.isArray(value) ? files : files[0];
    }

    config.data[key] = value;
  }

  return config;
};

const transformRequestValues = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  if (!config.data || typeof config.data !== 'object') {
    return config;
  }

  if (config.headers.getContentType()?.includes(ContentType.MULTIPART)) {
    const isPrimitiveValue = (value: unknown) => typeof value === 'string' || typeof value === 'number';

    for (const key in config.data) {
      let value = config.data[key];

      if (value === null) {
        value = '';
      } else if (Array.isArray(value)) {
        if (value.every(isPrimitiveValue)) {
          value = value.join(',');
        }
      }

      config.data[key] = value;
    }

    return config;
  }

  for (const key in config.data) {
    let value = config.data[key];

    if (value === '') {
      value = null;
    }

    config.data[key] = value;
  }

  return config;
};

const handleUnauthorizedError = async (error: AxiosError) => {
  const config = error.config;

  if (!config
    || refreshAccessTokenRetrying
    || PUBLIC_ENDPOINTS.includes(config.url as string)) {
    throw error;
  }

  if (refreshAccessTokenPromise) {
    await refreshAccessTokenPromise;

    return axiosInstance.request(config);
  }

  refreshAccessTokenPromise = new Promise<AxiosResponse>((resolve, reject) => {
    authService.refreshAccessToken()
      .then(() => {
        refreshAccessTokenRetrying = true;

        axiosInstance.request(config)
          .then(resolve)
          .catch(async (err) => {
            if (err.response?.status === HttpStatus.UNAUTHORIZED) {
              await authService.logout();
            }

            return reject(err);
          });
      })
      .catch(async () => {
        await authService.logout();

        reject(error);
      });
  }).finally(() => {
    refreshAccessTokenRetrying = false;
    refreshAccessTokenPromise = null;
  });

  return refreshAccessTokenPromise;
};

const getErrorMessage = (error: AxiosError): string => {
  const response = error.response ?? {} as AxiosResponse;
  const { data, status } = response;

  if (data && data.message) {
    let message = data.message;

    if (Array.isArray(message)) {
      message = message.join('; ');
    }

    return message;
  }

  const key = `common.errors.${status}`;

  if (lang.has(key)) {
    return lang.get(key);
  }

  return error.message;
};

const axiosInstance = axios.create({
  baseURL: config.API_URL,
  timeout: config.API_TIMEOUT,
  headers: {
    'Accept': ContentType.JSON,
    'Content-Type': ContentType.JSON,
  },
});

axiosInstance.interceptors.request.use((config) => {
  const token = authService.getAccessToken();

  if (token) {
    config.headers.setAuthorization(`Bearer ${token}`);
  }

  config = transformRequestFiles(config);
  config = transformRequestValues(config);

  return config;
});

axiosInstance.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  if (!isAxiosError(error)) {
    return Promise.reject(error);
  }

  if (error.response?.status === HttpStatus.UNAUTHORIZED) {
    try {
      return await handleUnauthorizedError(error);
    } catch (err) {
      error = err;
    }
  }

  error.message = getErrorMessage(error);

  return Promise.reject(error);
});

export default axiosInstance;
