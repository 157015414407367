import { useCallback, useMemo } from 'react';

import { config } from 'data';
import { Nullish } from 'types/common';
import { CurrencyCode } from 'types/models';

import useLang from './lang';

const useCurrencyFormatter = () => {
  const lang = useLang();

  const format = useCallback((code: Nullish<CurrencyCode>, fallback?: string) => {
    if (code === config.CURRENCY_CODE_ANY) {
      return lang.get('common.actions.any');
    }

    return code ?? fallback;
  }, [lang]);

  return useMemo(() => ({ format }), [format]);
};

export default useCurrencyFormatter;
