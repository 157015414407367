import { axios } from 'data';
import { Uuid } from 'types/common';
import { Provider, ProviderBasic, ProviderExtended } from 'types/models';
import { ProviderParams, ProvidersBasicResponse, ProvidersParams, ProvidersResponse } from 'types/services';

import { preparePaginatableParams, preparePaginatedData } from './helpers';

const getProviders = async (params: ProvidersParams = {}) => {
  const response = await axios.get<ProvidersResponse>('/payments/admin/provider', {
    params: preparePaginatableParams(params),
  });

  return preparePaginatedData<Provider>(response.data);
};

const getBasicProviders = async (params: ProvidersParams = {}) => {
  const response = await axios.get<ProvidersBasicResponse>('/payments/admin/provider/short', {
    params: preparePaginatableParams(params),
  });

  return preparePaginatedData<ProviderBasic>(response.data);
};

const getProvider = async (id: Uuid) => {
  const response = await axios.get<ProviderExtended>(`/payments/admin/provider/${id}`);

  return response.data;
};

const updateProvider = async (id: Uuid, params: Partial<ProviderParams>) => {
  await axios.patch<never>(`/payments/admin/provider/${id}`, params);
};

const activateProvider = async (id: Uuid) => {
  await updateProvider(id, { active: true });
};

const deactivateProvider = async (id: Uuid) => {
  await updateProvider(id, { active: false });
};

const providerService = {
  getProviders,
  getBasicProviders,
  getProvider,
  updateProvider,
  activateProvider,
  deactivateProvider,
};

export default providerService;
