import React, { FC } from 'react';

import { formatter, url } from 'helpers';
import { useLang } from 'hooks';
import { BusinessAccountBalance, TransactionStatus, UserInfo } from 'components/layout';
import { Descriptions, Form, Link, Modal } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { BusinessAccount, Transaction } from 'types/models';

import Title from './Title';

type ViewAdjustmentModalProps = ModalBaseProps & {
  businessAccount: BusinessAccount;
  transaction: Transaction;
};

const ViewAdjustmentModal: FC<ViewAdjustmentModalProps> = ({
  businessAccount,
  transaction,
  open,
  onClose,
}) => {
  const lang = useLang();

  return (
    <Modal
      title={<Title businessAccount={businessAccount} transaction={transaction} />}
      caption={<BusinessAccountBalance businessAccount={businessAccount} />}
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      open={open}
      onCancel={onClose}
    >

      <Descriptions
        items={[{
          label: lang.get('transaction.modal.accountId.label'),
          children: transaction.virtualAccount?.id ?? '-',
        }, {
          label: lang.get('transaction.modal.accountNumber.label'),
          children: transaction.virtualAccount?.externalId ?? '-',
        }, {
          label: lang.get('common.form.creationDate.label'),
          children: formatter.formatDateTime(transaction.createdAt),
        }, {
          label: lang.get('common.form.status.label'),
          children: <TransactionStatus status={transaction.status} />,
        }, {
          label: lang.get('transaction.modal.createdBy.label'),
          children: transaction.createdBy ? (
            <UserInfo
              name={formatter.formatName(transaction.createdBy.firstName, transaction.createdBy.lastName)}
              email={transaction.createdBy.email}
            />
          ) : '-',
        }, {
          label: lang.get('transaction.modal.updatedBy.label'),
          children: transaction.updatedBy ? (
            <UserInfo
              name={formatter.formatName(transaction.updatedBy.firstName, transaction.updatedBy.lastName)}
              email={transaction.updatedBy.email}
            />
          ) : '-',
        }]}
      />

      <Form.Divider />

      <Descriptions
        items={[{
          label: lang.get('transaction.modal.amount.label'),
          children: formatter.formatCurrency(transaction.originAmount, transaction.originCurrency),
        }, {
          label: lang.get('transaction.modal.transactionFee.label'),
          children: formatter.formatCurrency(transaction.transactionFee, transaction.originCurrency),
        }, {
          label: lang.get('transaction.modal.feeDetails.label'),
          children: transaction.feeCommission
            ? <Link href={url.toFeeCommission(transaction.feeCommission.id)}>{lang.get('common.actions.view')}</Link>
            : '-',
        }]}
      />

      <Form.Divider />

      <Descriptions
        items={[{
          label: lang.get('transaction.modal.id.label'),
          children: transaction.id,
        }, {
          label: lang.get('transaction.modal.referenceId.label'),
          children: transaction.clientReferenceId ?? '-',
        }, {
          label: lang.get('common.form.notes.label'),
          children: transaction.notes ?? '-',
        }]}
      />

    </Modal>
  );
};

export default ViewAdjustmentModal;
