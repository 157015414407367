import { config } from 'data';
import { clientService } from 'services';
import { useQuery } from 'hooks';
import { Uuid } from 'types/common';

const useClientVerificationDetailsQuery = (id?: Uuid) => useQuery({
  queryKey: [config.CLIENT_VERIFICATION_DETAILS_QUERY_KEY, id],
  queryFn: () => id ? clientService.getClientVerificationDetails(id) : null,
  showNotification: false,
});

export default useClientVerificationDetailsQuery;
