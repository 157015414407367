import React from 'react';

import { useLang } from 'hooks';
import { DashboardLayout } from 'layouts';
import { Grid } from 'components/ui';

import Commissions from './Commissions';
import Groups from './Groups';

const FeeManagementPage = () => {
  const lang = useLang();

  return (
    <DashboardLayout title={lang.get('feeManagement.title')}>
      <Grid.Row gutter={[8, 8]}>
        <Grid.Col span={24} md={12}>
          <Groups />
        </Grid.Col>
        <Grid.Col span={24}>
          <Commissions />
        </Grid.Col>
      </Grid.Row>
    </DashboardLayout>
  );
};

export default FeeManagementPage;
